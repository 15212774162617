import { LanguagesComponent } from './languages/languages.component';
import { GeographicalExpComponent } from './geographical-exp/geographical-exp.component';
import { FunctionalExpComponent } from './functional-exp/functional-exp.component';
import { ToolsSoftwaresComponent } from './tools-softwares/tools-softwares.component';
import { SkillComponent } from './skill/skill.component';
import { ConfirmationPopUpComponent } from 'libs/ui-base/src/lib/components/confirmation-pop-up/confirmation-pop-up.component';
import { UserSkills } from './../../../typedef/api-model';
import { SkillConstants } from './../../constants/skill.constant';
import { UploadDocComponent } from './../upload-doc/upload-doc.component';
import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { ViewUploadedDocsComponent } from '../view-uploaded-docs/view-uploaded-docs.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { SkillService } from './../../services/skill.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../../app.component';
import { ThrowStmt } from '@angular/compiler';
import { RouterHistoryService } from 'libs/ui-base/src/lib/services/router-history.service';
import { Location } from '@angular/common';
declare var $;
@Component({
  selector: 'hrs-ui-skill-review',
  templateUrl: './skill-review.component.html',
  styleUrls: ['./skill-review.component.scss'],
})
export class SkillReviewComponent implements OnInit {
  pageHeader;
  skillData: UserSkills = new UserSkills();
  @ViewChild('skill') skillComponent: SkillComponent;
  @ViewChild('toolAndSoft') toolsSoftwaresComponent: ToolsSoftwaresComponent;
  @ViewChild('functionalExp') functionalExpComponent: FunctionalExpComponent;
  @ViewChild('geographicalExp')
  geographicalExpComponent: GeographicalExpComponent;
  @ViewChild('languages') languagesComponent: LanguagesComponent;

  userId;
  uploadDocObj;
  addSkillObj = {};
  addDocObj = {};
  uploadedFiles = [];
  isSupervisorAction = false;
  isViewMode = false;
  isSkillSaved: boolean;
  locallyAddedSkills = [];
  preSelectedSkills = [];
  selectedItems = [];
  dropdownSettings = {};
  isCheckBoxTicked: boolean = false;
  ischeckBox: boolean = true;
  isHr: boolean = false;

  constructor(
    private skillService: SkillService,
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private historyService: RouterHistoryService,
    private location: Location 
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((queryparams) => {
      if (queryparams?.userId) {
        this.isHr = queryparams?.isHr;
        this.userId = queryparams?.userId;
        this.isViewMode = queryparams?.actionType == 'view' ? true : false;
        this.isViewMode = !this.appService.getIsEditWindowAvailable();
        this.isViewMode = this.isHr ? false : this.isViewMode;
        this.isSupervisorAction = this.isHr ? false : true;
        this.pageHeader = SkillConstants.EMPLOYEE_SKILL_REVIEW_HEADER;
      } else {
        this.authService.getLoggedInUser().subscribe((res) => {
          if (res && res.userId) {
            this.userId = res.userId;
          }
        });
        this.pageHeader = SkillConstants.MY_SKILL_REVIEW_HEADER;
        this.isViewMode = !this.appService.getIsEditWindowAvailable();
      }
    });
    // this.isViewMode = !this.appService.getIsEditWindowAvailable();
  }

  updateSkillData(skillData) {
    this.skillData = skillData;
  }

  openInfoPopup(popupMsg, buttons?) {
    if (!buttons) {
      buttons = [SkillConstants.OK];
    }
    const dialogRef = this.dialog.open(PopupModalComponent, {
      width: SkillConstants.WIDTH_350PX,
      data: {
        message: [popupMsg],
        button: buttons,
      },
    });
    return dialogRef;
  }

  updateStatus() {
    if (this.skillComponent.skillForm.dirty) {
      const popupMsg = SkillConstants.SAVE_SKILL_BEFORE_SUBMIT_MSG;
      const dialogRef = this.openInfoPopup(popupMsg);
      dialogRef.afterClosed().subscribe((res) => {
        this.skillComponent.saveSkillBtn.nativeElement.focus();
      });
    } else if (this.toolsSoftwaresComponent.toolsAndSoftwaresForm.dirty) {
      const popupMsg = SkillConstants.SAVE_TOOLS_AMD_SOFT_BEFORE_SUBMIT_MSG;
      this.openInfoPopup(popupMsg);
    // } else if (this.functionalExpComponent.functionalExpForm.dirty) {
    //   const popupMsg = SkillConstants.SAVE_FUNCTIONAL_EXP_BEFORE_SUBMIT_MSG;
    //   this.openInfoPopup(popupMsg);
    } else if (this.geographicalExpComponent.geographicalExpForm.dirty) {
      const popupMsg = SkillConstants.SAVE_GEOGRAPHICAL_EXP_BEFORE_SUBMIT_MSG;
      this.openInfoPopup(popupMsg);
    } else {
      let popupMsg = '';
      if (this.isSupervisorAction) {
        popupMsg = SkillConstants.SUPERVISOR_UPDATE_MSG;
      } else {
        popupMsg = SkillConstants.EMPLOYEE_UPDATE_MSG;
      }
      const button = [SkillConstants.YES, SkillConstants.CANCEL];
      const dialogRef = this.openInfoPopup(popupMsg, button);
      dialogRef.afterClosed().subscribe((res) => {
        if (res == SkillConstants.YES) {
          let updateStatusObj = {};
          updateStatusObj['requestId'] = this.userId;
          updateStatusObj['action'] = 'submit';
          this.skillService.updateStatus(updateStatusObj).subscribe((res) => {
            if (res && res.version) {
              this.showSkillSubmitPopup();
              this.skillData.userSkills.status = res.status;
            }
          });
        }
      });
    }
  }

  public onChecked(event) {
    this.isCheckBoxTicked = event.target.checked ? true : false;
    if (this.isCheckBoxTicked) this.ischeckBox = false;
    else this.ischeckBox = true;
  }

  goBack() {
    this.location.back();
  }

  showSkillSubmitPopup() {
    let popupMsg = '';
    if (this.isSupervisorAction || this.isHr) {
      popupMsg = SkillConstants.SKILL_REVIEWED_MSG;
    } else {
      popupMsg = SkillConstants.SKILL_SENT_FOR_REVIEW_MSG;
    }
    const dialogRedict = this.dialog.open(ConfirmationPopUpComponent, {
      data: {
        requestText: popupMsg,
        isReqForSupport: false,
      },
      disableClose: true,
    });
    dialogRedict.afterClosed().subscribe((response) => {
      // if (response == 'OK') {
      window.location.reload();
      // }
    });
  }

  collapseAll() {
    $('.card-header').addClass('collapsed');
    $('.card-body').removeClass('show');
  }
  expandAll() {
    $('.card-header').removeClass('collapsed');
    $('.card-body').addClass('show');
  }

  //UPLOAD FILE CODE
  // openUploadDocPopup(skillCd) {
  //   let dialogRef = this.dialog.open(UploadDocComponent, {
  //     width: '700px',
  //     data: {
  //       skillCd: skillCd
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(res => {
  //     this.uploadDocObj = res;
  //     console.log(" this.uploadDocObj", this.uploadDocObj)
  //     if (this.uploadDocObj) {
  //       this.addSkillObj['fileToSkillMap'] = this.uploadDocObj.fileToSkillMap;
  //       //Object.assign(this.uploadDocObj.fileToSkillMap, this.addSkillObj['fileToSkillMap']);
  //       this.uploadedFiles.push(this.uploadDocObj.file);
  //     }
  //   })
  // }

  // viewUploadedDocs(skillCd) {
  //   let dialogRef = this.dialog.open(ViewUploadedDocsComponent, {
  //     width: '700px',
  //     data: {
  //       skillCd: skillCd,
  //       isViewMode: this.isViewMode,
  //       userId: this.userId,
  //       localFiles: this.uploadDocObj
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(res => {
  //     console.log("res", res)
  //     if (res === 'delete') {
  //       this.uploadDocObj = {};
  //     }
  //     // this.dialog.open(PopupModalComponent, {
  //     //   width: '500px',
  //     //   position: { top: '20px' },
  //     //   data: {
  //     //     message: ['', `Document deleted successfully.`],
  //     //     button: [
  //     //       {
  //     //         buttonName: 'OK',
  //     //         buttonClass: 'commonBtn mr-2'
  //     //       }
  //     //     ]
  //     //   },
  //     //   disableClose: false
  //     // }).afterClosed().subscribe(response => {
  //     // });
  //   });
  // }
}

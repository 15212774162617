// export interface profile {
//   psNumber: number,
//   custom: Object,
//   createdBy: string,
//   updatedOn: string,
//   updatedBy: string,
//   id: string,
//   level: number,
//   code: string,
//   title: string,
//   sequence: number,
//   parent: string,
//   image: string,
//   active: boolean,
//   children: []
// }

export class Profile {
  id: string;
  userId: string;
  loginId: string;
  userName: string;
  email: string;
  mobile: string;
  address: string;
  preferredLanguage: string;
  roles: Array<string>;
  organization: Organization;
  localUser: boolean;
  supervisorId: string;
  active: boolean;
  custom: any;
}
export interface Organization {
  id: string;
  code: string;
  name: string;
  address: string;
  phone: string;
  email: string;
}
export interface SkillAndProfiencyModel {
  skillList: SkillHierarchy;
  profiency: ProfiencyMaster;
}

export interface ProfiencyMaster {
  Id: string;
  profiencyCd: string;
  profiencyName: string;
  profiencyType: string;
  profiencyTypeCd: string;
  profiencyValue: Array<string>;
  active: boolean;
}

export interface SkillHierarchy {
  skillCd: string;
  skillName: string;
  skillDesc: string;
  parentId: string;
  orgId: string;
  version: number;
  competency: string;
  domain: string;
  text: string;
  value: string;
  children: any[];
}

export class UserSkills {
  userSkills: {
    version?: number;
    createdOn?: string;
    creatorId?: string;
    creatorName?: string;
    modifiedOn?: string;
    modifierId?: string;
    modifierName?: string;
    status?: string;
    statusName?: string;
    stage?: string;
    stageName?: string;
    ownerRole?: string;
    id?: string;
    userId?: string;
    userName?: string;
    supervisorCd?: string;
    supervisorName?: string;
    skills: Array<SkillRatingModel>;
    active?: boolean;
  };
  supportingDocs: [
    {
      version: number;
      createdOn: string;
      creatorId: string;
      creatorName: string;
      modifiedOn: string;
      modifierId: string;
      modifierName: string;
      id: string;
      fileName: string;
      storageId: string;
      entityType: string;
      entityId: string;
      docTypeCd: string;
      docTypeName: string;
      active: boolean;
      logicalFileName: string;
      fileExtension: string;
      placeholder: boolean;
      attributesMap: {
        skill: string;
      };
    }
  ];
}

export class AddSkillRequest {
  userId: string;
  skills: Array<SkillRatingModel>;
  fileToSkillMap: FileSkillRequestModel;
  supportingDocs: any;
  userType: any;
}

export interface SkillRatingModel {
  skillCd: string;
  skillName: string; //adminstrator
  skillRemarks: string;
  skillDomain: any;
  parentId: string;
  parentName: string;
  ratingVal?: number;
  supervisorRating: string;
  supervisorRemark: string;
  profiencyType: string; //rating
  profiencyValue: string; // beginner
  competency: string;
  weightage: number;
  noOfYears: any;
  addedBy: any;
}

export interface FileSkillRequestModel {
  name: string;
  skillCd: string;
  description: string;
}

export class AddWindow {
  financialYear: string;
  type: string;
  description: string;
  startDate: string;
  endDate: string;
  userIds: Array<string>;
  status: string;
  remarks: string;
  action: string;
}

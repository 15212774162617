import { UtilityService } from '../../services/utility.service';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FileUploadValidator } from './../../validators/global-validators';
import { GlobalValidationConstants as GVConstants } from 'libs/ui-base/src/lib/constants/global-validation-constants';
import { PopupModalComponent } from './../popup-modal/popup-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UploadComponentConfig, UploadEmitEvent } from '../../typedef/api-model-class';

@Component({
  selector: 'base-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  public uploadedFileName: string;
  fileEvent: any = undefined;
  @Input('uploadConfig') public uploadConfig: UploadComponentConfig;
  @Output('uploadEventEmitter') private uploadEventEmitter: EventEmitter<UploadEmitEvent> = new EventEmitter();
  @Input() showUploadButton;
  @Input() showFileName;
  constructor(private dialog: MatDialog, private utilityService: UtilityService) { }

  ngOnInit(): void {
    if (!this.showUploadButton) {
      this.showUploadButton = 'true';
    }
  }

  ngOnChanges() { if (this.uploadConfig.reset) { this.resetFileEvent(); this.uploadConfig.reset = false; } }

  fileChanged(event: any) {
    this.fileEvent = event;
    const MB = 1048576;
    const fileName = this.fileEvent.target.files[0].name;
    const validationResult = FileUploadValidator(event, this.uploadConfig.permissibleSize, this.uploadConfig.permissibleExtensions);

    if (validationResult.hasInvalidFormat) {
      this.invokeWarning(this.utilityService.getMessage(GVConstants.VALIDATION, 'INVALID_FILE_FORMAT', [fileName]))
      return;

    } else if (validationResult.hasSizeExceeded) {
      this.invokeWarning(this.utilityService.getMessage(GVConstants.VALIDATION,
        'MAX_SIZE_EXCEEDED_GENERIC_FILE', [this.uploadConfig.permissibleSize / MB]));
      return;
    }

    const confirmUploadMessage = this.utilityService.getMessage('GENERIC', 'FILE_UPLD_CONFIRMATION', [fileName]);
    this.invokeConfirmation(confirmUploadMessage, fileName);
  }

  invokeConfirmation(message, fileName) {
    const messages = ['Confirmation', message];
    const buttons = ['Yes', 'Cancel']
    this.showPopup(messages, buttons, fileName);
  }

  invokeWarning(message: string) {
    const messages = ['Warning', message];
    const buttons = [{ buttonName: 'Discard', buttonClass: 'commonBtn commonBtnTransparent mr-2' }];
    this.showPopup(messages, buttons, '');
  }

  showPopup(messages: any, buttons: any, selectedFileName) {

    const dialogRef = this.dialog.open(PopupModalComponent, {
      width: '300px',
      data: { message: messages, button: buttons },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'Yes') {
        this.uploadedFileName = selectedFileName;
        this.uploadEventEmitter.emit({ continueUpload: true, fileEvent: this.fileEvent });
      } else {
        this.uploadEventEmitter.emit({ continueUpload: false, fileEvent: undefined });
        this.uploadedFileName = ''
        this.resetFileEvent();
      }
    });
  }

  resetFileEvent() { document.getElementById(this.uploadConfig.inputFieldId)['value'] = ''; this.fileEvent = undefined; }

}
import { ChartPopupComponent } from './chart-popup/chart-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SkillConstants } from './../../skill/constants/skill.constant';
import { SampleData } from './../../typedef/data';
import { DashboardService } from './../../services/dashboard.service';
import { BarChartModel, PieChartModel } from './../../../../../../libs/ui-base/src/lib/typedef/api-model-class';
import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../../constants/global-constants';
import _ from "lodash"
import { SkillHierarchyService } from '../../services/skill-hierarchy.service';
import { SkillService } from '../../skill/services/skill.service';


@Component({
  selector: 'hrs-ui-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  profByLevel1Skills = [];
  subSkillData = [];
  toolsSoftProficiencyData = [];
  functionalEmpCountData = [];
  functionalEmpAvgExpData = [];
  geographyEmpCountData = [];
  geographyEmpAvgExpData = [];
  SkillConstants = SkillConstants;
  subChart: boolean;

  //Chart Inputs
  skillsLTitle = SkillConstants.SKILL_COMPETENCY;
  toolsSoftLTitle = SkillConstants.TOOLSANDSOFTWARE_COMPETENCY;
  functionLTitle = SkillConstants.FUNCTIONS;
  geographyLTitle = SkillConstants.GEOGRAPHY;


  //Chart CSS Data
  skillDataWidth = 1200;
  subSkillDataWidth = 1200;
  toolsSoftDataWidth = 1200;
  functionalDataWidth = 1200;
  geographyDataWidth = 1200;

  //Combo Chart Data
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = true;
  showYAxisLabel = true;
  showGridLines = true;
  animations: boolean = true;
  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Average Experience';
  view: any[] = [700, 400];
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b']
  };

  //pie chart configuration 
  explodeSlices = false;
  showLabels = true;
  doughnut = false;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#008080', '#800000']
  };

  // Actual code Stats here * Above lines are for demo only
  config: any;
  chartConfig = new BarChartModel();
  multiBarChartConfig = new BarChartModel();
  pieChartConfig = new PieChartModel();
  advPieChartConfig = new PieChartModel();

  filters:any = {functions:[], geos:[], domains:[]};
  filterValues:any = {skill:"", geography:"",domain:""};
  subSkillToolsData: any[];

  constructor(private dashboardService: DashboardService, 
    private skillHierarchyService: SkillHierarchyService,
    private skillService: SkillService,
    private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadFilters();
    this.loadDataWithFilters();

    this.dashboardService.getFunctionEmpCountData().subscribe(res => {
      this.functionalDataWidth = this.dashboardService.barWidth * res.length;
      let lineChartData = [{
        name: 'Average Experience',
        series: []
      }]
      res.forEach(element => {
        let e1 = _.pick(element, ['name', 'value']);
        let e2 = _.pick(element, ['name', 'avg']);
        e1.value = Math.round((e1.value + Number.EPSILON) * 100) / 100;
        this.functionalEmpCountData.push(e1);
        lineChartData[0].series.push(e2)
      });
      lineChartData[0].series = lineChartData[0].series.map(item => {
        return { name: item.name, value: item.avg }
      })
      this.functionalEmpAvgExpData = lineChartData;
    })
    this.dashboardService.getGeographyEmpCountData().subscribe(res => {
      this.geographyDataWidth = this.dashboardService.barWidth * res.length;
      // this.formatExpAndCountData(res, this.geographyEmpCountData, this.geographyEmpAvgExpData)
      let lineChartData = [{
        name: 'Average Experience',
        series: []
      }]
      res.forEach(element => {
        let e1 = _.pick(element, ['name', 'value']);
        let e2 = _.pick(element, ['name', 'avg']);
        e1.value = Math.round((e1.value + Number.EPSILON) * 100) / 100;
        this.geographyEmpCountData.push(e1);
        lineChartData[0].series.push(e2)
      });
      lineChartData[0].series = lineChartData[0].series.map(item => {
        return { name: item.name, value: item.avg }
      })
      this.geographyEmpAvgExpData = lineChartData;
    })

    this.dashboardService.getSkillChat().subscribe(res => {
      this.setBarChartConfiguration(res);
      this.setMultiBarChartConfiguration(res);
      this.setPIEChartConfiguration(res)
      this.setAdvancedPIEChart(res);
    })

    // this.dashboardService.getMultiBarchartSkillData().subscribe(res => {
    //   this.setMultiBarChartConfiguration(res);
    // })

  }

  loadDataWithFilters() {
    this.dashboardService.getSkillData(this.filterValues).subscribe(res => {
      this.profByLevel1Skills = this.dashboardService.roundOffValue(res);
      this.skillDataWidth = this.dashboardService.barWidth * res.length; 
      this.subSkillData = null;
    });
    this.dashboardService.getSubSkillData(SkillConstants.TOOLSANDSOFTWARE_COMPETENCY, null, this.filterValues).subscribe(res => {
      this.toolsSoftProficiencyData = this.dashboardService.roundOffValue(res);
      this.toolsSoftDataWidth = this.dashboardService.barWidth * res.length;
    })
  }

  loadFilters() {
    this.skillHierarchyService.getSkillHierarchy(SkillConstants.FUNCTIONAL).subscribe(data =>
      this.filters.functions = data);
    this.skillHierarchyService.getSkillHierarchy(SkillConstants.GEOGRAPHICAL).subscribe(data =>
      this.filters.geos = data);
      this.skillService.getSkillDomains().subscribe(data=>this.filters.domains = data);
  }

  formatExpAndCountData(res, empCountData, empAvgData) {
    let lineChartData = [{
      name: 'Average Experience',
      series: []
    }]
    res.forEach(element => {
      let e1 = _.pick(element, ['name', 'value']);
      let e2 = _.pick(element, ['name', 'avg']);
      this.geographyEmpCountData.push(e1);
      lineChartData[0].series.push(e2)
    });
    lineChartData[0].series = lineChartData[0].series.map(item => {
      return { name: item.name, value: item.avg }
    })
    this.geographyEmpAvgExpData = lineChartData;
  }
  setBarChartConfiguration(data?) {
    this.chartConfig.chartType = GlobalConstants.CHART_TYPE_BAR;
    this.chartConfig.showGradient = false;
    this.chartConfig.animations = true;
    this.chartConfig.chartwidth = 500;
    this.chartConfig.chartHeight = 400;
    this.chartConfig.chartTitle = "Employee Skill Data Chart";
    this.chartConfig.showGridLines = true;
    // this.chartConfig.barPadding = 40;
    this.chartConfig.colorScheme = {
      domain: ['#5AA454', 'aqua-blue', '#C7B42C', '#AAAAAA']
    };;
    this.chartConfig.xAxisLabel = "Skills";
    this.chartConfig.yAxisLabel = "Employee (In Thousands)"
    this.view = [this.chartConfig.chartwidth, this.chartConfig.chartHeight];
    this.chartConfig.data = data;
    this.chartConfig.legendTitle = "Years";

    this.config = this.chartConfig;

  }

  setMultiBarChartConfiguration(data) {
    this.multiBarChartConfig.chartType = GlobalConstants.CHART_TYPE_MULTIBAR;
    this.multiBarChartConfig.showGradient = false;
    this.multiBarChartConfig.animations = true;
    this.multiBarChartConfig.chartwidth = 500;
    this.multiBarChartConfig.chartHeight = 400;
    this.multiBarChartConfig.chartTitle = "Employee Rating Data Chart";
    this.multiBarChartConfig.showGridLines = true;
    this.multiBarChartConfig.colorScheme = {
      domain: ['SkyBlue', 'Tomato', 'Silver', 'Tomato']
    };;
    this.multiBarChartConfig.xAxisLabel = "Skills";
    this.multiBarChartConfig.groupPadding = 20;
    this.multiBarChartConfig.yAxisLabel = "Employee "
    this.view = [this.multiBarChartConfig.chartwidth, this.multiBarChartConfig.chartHeight];
    this.multiBarChartConfig.legendTitle = "Ratings";
    this.multiBarChartConfig.data = data;
    this.config = this.chartConfig;

  }

  setPIEChartConfiguration(data) {

    this.pieChartConfig.chartType = GlobalConstants.CHART_TYPE_PIE;
    this.pieChartConfig.showGradient = false;
    this.pieChartConfig.animations = true;
    this.pieChartConfig.chartwidth = 900;
    this.pieChartConfig.chartHeight = 600;
    this.pieChartConfig.legend = true;
    this.pieChartConfig.chartTitle = "Employee Data Chart";
    this.pieChartConfig.showGridLines = true;
    this.pieChartConfig.colorScheme = {
      domain: ['#5AA454', 'aqua-blue', '#C7B42C', '#AAAAAA']
    };;
    this.pieChartConfig.explodeSlices = false;
    this.pieChartConfig.showLabels = true;
    this.pieChartConfig.doughnut = false;
    this.pieChartConfig.data = data;

  }

  setAdvancedPIEChart(data?) {
    this.advPieChartConfig.showGradient = false;
    this.advPieChartConfig.animations = true;
    this.advPieChartConfig.chartwidth = 900;
    this.advPieChartConfig.chartHeight = 600;
    this.advPieChartConfig.chartTitle = "Employee Data Chart";
    this.advPieChartConfig.showGridLines = true;
    this.advPieChartConfig.colorScheme = {
      domain: ['#5AA454', 'aqua-blue', '#C7B42C', '#AAAAAA']
    };;
    this.advPieChartConfig.explodeSlices = false;
    this.advPieChartConfig.showLabels = true;
    this.advPieChartConfig.doughnut = true;
    this.advPieChartConfig.data = data;
    this.advPieChartConfig.chartType = GlobalConstants.CHART_TYPE_ADVANCED_PIE;
  }

  onSelect(event) {
    this.subChart = true;
    window.scrollTo(0, 600);
  }


  onSkillSelect(event) {
    this.subChart = true;
    this.dashboardService.getSubSkillData('Skills', event.name, this.filterValues).subscribe(res => {
      this.subSkillData = this.dashboardService.roundOffValue(res);
      this.subSkillDataWidth = this.dashboardService.barWidth * res.length;
    });
    this.dashboardService.getSubSkillToolsData(SkillConstants.TOOLSANDSOFTWARE_COMPETENCY, event.name, this.filterValues).subscribe(res => {
      this.subSkillToolsData = this.dashboardService.roundOffValue(res);
    });
    
    // this.dashboardService.getEmpCountAvgProfData(event.name, this.filterValues).subscribe(res => {
    //   let dialogData = {
    //     chartName: 'Employee Count by Proficiency for Sub-skill',
    //     chartData: this.dashboardService.roundOffValue(res),
    //     chartType: 'pie-chart'
    //   }
    //   this.dashboardService.invokeDialog(ChartPopupComponent, SkillConstants.HEIGHT_90_PERCENT, dialogData,
    //     SkillConstants.HEIGHT_90_PERCENT)
    //     .afterClosed().subscribe(newSkills => {
    //     });
    // })
    window.scrollTo(0, 600);
  }

  onSubSkillSelect(event) {
    this.dashboardService.getSubSkillProfData(event.name, this.filterValues).subscribe(res => {
      let dialogData = {
        chartName: 'Employee Count by Proficiency for Sub-skill',
        chartData: this.dashboardService.roundOffValue(res),
        chartType: 'pie-chart'
      }
      this.dashboardService.invokeDialog(ChartPopupComponent, SkillConstants.HEIGHT_90_PERCENT, dialogData,
        SkillConstants.HEIGHT_90_PERCENT)
        .afterClosed().subscribe(newSkills => {
        });
    })
  }

  onToolsSoftSelect(event) {
    this.dashboardService.getToolsSoftProfData(event.name, this.filterValues).subscribe(res => {
      let dialogData = {
        chartName: 'Employee Count by Proficiency for Tool/Software',
        chartData: this.dashboardService.roundOffValue(res),
        chartType: 'pie-chart'
      }
      this.dashboardService.invokeDialog(ChartPopupComponent, SkillConstants.HEIGHT_90_PERCENT, dialogData,
        SkillConstants.HEIGHT_90_PERCENT)
        .afterClosed().subscribe(newSkills => {
        });
    })
  }

  onFunctionSelect(event) {
    this.dashboardService.getFunctionRangeEmpCountData(SkillConstants.FUNCTIONAL_COMPETENCY, event.name).subscribe(res => {
      let expRangeWiseData = this.dashboardService.roundOffValue(this.formatData(res));
      let dialogData = {
        chartName: 'Employee Count by Experience Level for Function',
        chartData: expRangeWiseData,
        chartType: 'bar-vertical'
      }
      this.dashboardService.invokeDialog(ChartPopupComponent, SkillConstants.HEIGHT_90_PERCENT, dialogData,
        SkillConstants.HEIGHT_90_PERCENT)
        .afterClosed().subscribe(newSkills => {
        });
    })
  }

  onGeographySelect(event) {
    this.dashboardService.getFunctionRangeEmpCountData(SkillConstants.GEOGRAPHICAL_COMPETENCY, event.name).subscribe(res => {
      let expRangeWiseData = this.dashboardService.roundOffValue(this.formatData(res));
      let dialogData = {
        chartName: 'Employee Count by Experience Range for Geography',
        chartData: expRangeWiseData,
        chartType: 'bar-vertical'
      }
      this.dashboardService.invokeDialog(ChartPopupComponent, SkillConstants.HEIGHT_90_PERCENT, dialogData,
        SkillConstants.HEIGHT_90_PERCENT)
        .afterClosed().subscribe(newSkills => {
        });
    })
  }

  formatData(res) {
    let countBelowFive = 0;
    let countBelowTen = 0;
    let countBelowFifteen = 0;
    let countBelowTwenty = 0;
    let countBelowTwentyFive = 0;
    let countAboveTwentyFive = 0;
    let expRangeWiseData = [];

    res.forEach(element => {
      if (element.name <= 5) {
        countBelowFive = + element.value;
      } else if (element.name <= 10) {
        countBelowTen = + element.value;
      } else if (element.name <= 15) {
        countBelowFifteen = + element.value;
      } else if (element.name <= 20) {
        countBelowTwenty = + element.value;
      } else if (element.name <= 25) {
        countBelowTwentyFive = + element.value;
      } else if (element.name > 25) {
        countAboveTwentyFive = + element.value;
      }
    });
    let lessThanFive = { name: '0-5', value: countBelowFive };
    let lessThanTen = { name: '6-10', value: countBelowTen };
    let lessThanFifteen = { name: '11-15', value: countBelowFifteen };
    let lessThanTwenty = { name: '16-20', value: countBelowTwenty };
    let lessThanTwentyFive = { name: '21-25', value: countBelowTwentyFive };
    let greaterThanTwentyFive = { name: 'More than 26', value: countAboveTwentyFive };

    expRangeWiseData.push(lessThanFive);
    expRangeWiseData.push(lessThanTen);
    expRangeWiseData.push(lessThanFifteen);
    expRangeWiseData.push(lessThanTwenty);
    expRangeWiseData.push(lessThanTwentyFive);
    expRangeWiseData.push(greaterThanTwentyFive);
    return expRangeWiseData;
  }

}



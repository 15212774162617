import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { SkillService } from './../../services/skill.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';

@Component({
  selector: 'hrs-ui-view-uploaded-docs',
  templateUrl: './view-uploaded-docs.component.html',
  styleUrls: ['./view-uploaded-docs.component.scss']
})
export class ViewUploadedDocsComponent implements OnInit {
  docData = [];
  isViewMode = false;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public popupData: any, private authService: AuthService,
    private skillService: SkillService,
    private dialog: MatDialog,
    @Optional() public dialogRef: MatDialogRef<ViewUploadedDocsComponent>) { }
  ngOnInit(): void {
    this.isViewMode = this.popupData.isViewMode;
    this.skillService.getSkillList(this.popupData.userId).subscribe(skillData => {
      this.docData = skillData.supportingDocs?.filter(obj => {
        return obj.attributesMap.skill === this.popupData.skillCd;
      });
      if (this.popupData.localFiles) {
        let docDataObj = {
          file: this.popupData.localFiles.file,
          fileName: this.popupData.localFiles.file.name,
          attributesMap: {
            description: this.popupData.localFiles.fileDesc
          },
          isLocalFile: true
        }
        this.docData.push(docDataObj);
        console.log("this.popupData.localFiles", this.popupData.localFiles)

      }
      console.log("this.docData", this.docData)
    })
  }

  downloadDoc(doc) {
    console.log("doc>>>", doc)
    if (doc.isLocalFile) {
      var link = document.createElement("a");
      var file = this.popupData.localFiles.file;
      link.download = this.popupData.localFiles.file.name;
      link.href = URL.createObjectURL(file);
      link.click();
    } else {
      this.skillService.downloadSkillDoc(doc.id);
    }
  }


  close() {
    this.dialogRef.close();
  }

  deleteDoc(doc, index) {
    const dialogRef = this.dialog.open(PopupModalComponent, {
      width: '600px',
      data: {
        message: ['Are you sure you want to delete Doc?'],
        button: ['OK', 'Cancel']
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      console.log(res)
      if (res == 'OK') {
        this.authService.getLoggedInUser().subscribe(res => {
          if (doc.isLocalFile) {
            this.docData = this.docData.slice(index);
            this.dialogRef.close('delete');
          } else {
            this.skillService.deleteSkillDoc(res.userId, doc.id).subscribe(res => {
              if (res.result === 'success') {
                this.dialogRef.close();
              }
            })
          }

        })
      }
    })

  }

}

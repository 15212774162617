import { UtilityService } from 'libs/ui-base/src/lib/services/utility.service';
import { ActionRenderComponent } from "libs/ui-base/src/lib/components/action-render.component";
import { GridModel } from "libs/ui-base/src/lib/typedef/common-models";
import { SkillConstants } from "./skill.constant";


const utilityService = new UtilityService(null);

export function fetchSkillReviewGridData(): GridModel {
  return {
    heading: 'Manage Team Skills',
    class: 'agGridClass ag-theme-alpine',
    isPagination: true,
    resetFilter: true,
    exportExcel: true,
    csvFileName: 'Skill_review_',
    serverURL: SkillConstants.GET_SKILL_REVIEW_GRID,
    columns: [
      {
        headerName: 'PS Number',
        field: 'userId',
        sortable: true,
        filter: true,
        width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Employee Name',
        field: 'userName',
        sortable: true,
        filter: true,
        width: 320,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Last Updated On',
        field: 'modifiedOn',
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: utilityService.filterDate,
          browserDatePicker: true
        },
        width: 320,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Skill Updated',
        field: 'skillsUpdated',
        sortable: true,
        filter: true,
        width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Skill Reviewed',
        field: 'skillsReviewed',
        sortable: true,
        filter: true,
        width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 300,
        resizable: false,
        autoHeight: true,
        cellRendererFramework: ActionRenderComponent,
        cellRendererParams: {
          nextPageNavigation: {
            url: SkillConstants.SKILL_REVIEW_ROUTE,
            //  viewUrl: SkillConstants.VIEW_OP_DETAILS_ROUTE,
            parameter: 'userId'
          },
          actionConfig: {
            isReview: true
          }
        }
      }
    ],
    serverMapping: {
      userId: { name: 'userId' },
      userName: { name: 'userName' },
      modifiedOn: { name: 'modifiedOn', mapping: 'dateWithMonthNameTimeFormat' },
      skillsUpdated: { name: 'skillsUpdated' },
      skillsReviewed: { name: 'skillsReviewed' }
    }
  };
};

export function fetchSkillGridDataForHR(): GridModel {
  return {
    heading: 'HR Skills',
    class: 'agGridClass ag-theme-alpine',
    isPagination: true,
    resetFilter: true,
    exportExcel: true,
    csvFileName: 'HR_Skills_',
    serverURL: SkillConstants.GET_HR_SKILL_GRID,
    columns: [
      {
        headerName: 'Employee PS Number',
        field: 'userId',
        sortable: true,
        filter: true,
        width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Employee Name',
        field: 'userName',
        sortable: true,
        filter: true,
        width: 170,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Supervisor PS Number',
        field: 'supervisorCd',
        sortable: true,
        filter: true,
        width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Supervisor Name',
        field: 'supervisorName',
        sortable: true,
        filter: true,
        width: 170,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Skill Updated',
        field: 'skillsUpdated',
        sortable: true,
        filter: true,
        width: 150,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Skill Reviewed',
        field: 'skillsReviewed',
        sortable: true,
        filter: true,
        width: 150,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Last Updated On',
        field: 'modifiedOn',
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: utilityService.filterDate,
          browserDatePicker: true
        },
        width: 320,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Status',
        field: 'statusName',
        sortable: true,
        filter: true,
        width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 150,
        resizable: false,
        autoHeight: true,
        cellRendererFramework: ActionRenderComponent,
        cellRendererParams: {
          nextPageNavigation: {
            url: SkillConstants.SKILL_REVIEW_ROUTE,
            //  viewUrl: SkillConstants.VIEW_OP_DETAILS_ROUTE,
            parameter: 'userId'
          },
          actionConfig: {
            isEdit: true,
            isHr: true
          }
        }
      }
    ],
    serverMapping: {
      userId: { name: 'userId' },
      userName: { name: 'userName' },
      supervisorCd: { name: 'supervisorCd' },
      supervisorName: { name: 'supervisorName' },
      modifiedOn: { name: 'modifiedOn', mapping: 'dateWithMonthNameTimeFormat' },
      statusName: { name: 'statusName' },
      skillsUpdated: { name: 'skillsUpdated' },
      skillsReviewed: { name: 'skillsReviewed' }
    }
  };
};

export function getEmployeeSearchData(skillCd): GridModel {
  return {
    heading: 'Employee Search',
    class: 'agGridClass ag-theme-alpine',
    isPagination: true,
    resetFilter: true,
    exportExcel: true,
    csvFileName: 'Employee_search_',
    serverURL: SkillConstants.EMPLOYEE_SEARCH + skillCd,
    columns: [
      {
        headerName: 'Skill Name',
        field: 'skillName',
        sortable: true,
        filter: true,
        width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Employee PS Number',
        field: 'userId',
        sortable: true,
        filter: true,
        width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Employee Name',
        field: 'userName',
        sortable: true,
        filter: true,
        width: 170,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Supervisor PS Number',
        field: 'supervisorCd',
        sortable: true,
        filter: true,
        width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Supervisor Name',
        field: 'supervisorName',
        sortable: true,
        filter: true,
        width: 170,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Supervisor Rating',
        field: 'supervisorRating',
        sortable: true,
        filter: true,
        width: 170,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Last Updated On',
        field: 'modifiedOn',
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: utilityService.filterDate,
          browserDatePicker: true
        },
        width: 320,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 150,
        resizable: false,
        autoHeight: true,
        cellRendererFramework: ActionRenderComponent,
        cellRendererParams: {
          nextPageNavigation: {
            url: SkillConstants.SKILL_REVIEW_ROUTE,
            //  viewUrl: SkillConstants.VIEW_OP_DETAILS_ROUTE,
            parameter: 'userId'
          },
          actionConfig: {
            isView: true
          }
        }
      }
    ],
    serverMapping: {
      userId: { name: 'userId' },
      userName: { name: 'userName' },
      supervisorCd: { name: 'supervisorCd' },
      modifiedOn: { name: 'modifiedOn', mapping: 'dateWithMonthNameTimeFormat' },
      supervisorName: { name: 'supervisorName' },
      skillName: { name: 'skillName' },
      supervisorRating: { name: 'supervisorRating' }
    }
  };
};
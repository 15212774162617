import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class ApiClient {
  apiUrl: any;
  constructor(private http: HttpClient, @Inject('Environment') env: any) {
    this.apiUrl = env.domainUrl;
  }

  post(url, body, otherParamets?: any) {
    return this.http.post(this.apiUrl + url, body, { headers: new CustomHttpHeaders(otherParamets) })
  }

  patch(url, body, otherParamets?: any) {
    return this.http.patch(this.apiUrl + url, body, { headers: new CustomHttpHeaders(otherParamets) })
  }

  delete(url, body, otherParamets?: any) {
    return this.http.delete(this.apiUrl + url, body)
  }

  get(url, otherParamets?: any) {
    return this.http.get(this.apiUrl + url, { headers: new CustomHttpHeaders(otherParamets) })
  }

  getWithParams(url, params?:any, otherParamets?: any) {
    return this.http.get(this.apiUrl + url, {params:params, headers: new CustomHttpHeaders(otherParamets) })
  }

  getMethodResponseParameter(url, response, otherParamets?: any) {
    response = {
      ...response,
      headers: new CustomHttpHeaders(otherParamets)
    }
    return this.http.get(this.apiUrl + url, response)
  }

  put(url, base64, otherParamets?: any) {
    return this.http.put(this.apiUrl + url, base64, { headers: new CustomHttpHeaders(otherParamets) })
  }


  uploadFile(url, formdata) {
    const headers = new HttpHeaders({
      file: 'file',
    });
    return this.http.post<any>(this.apiUrl + url, formdata, {
      reportProgress: true,
      observe: 'events',
      headers: headers
    });
  }

}

export class CustomHttpHeaders extends HttpHeaders {
  constructor(public otherParams: any) {
    super();
  }
}

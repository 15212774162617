import { ConfirmationPopUpComponent } from './components/confirmation-pop-up/confirmation-pop-up.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartComponent } from './components/chart/chart.component';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StarterService } from './services/starter.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { PopupModalComponent } from './components/popup-modal/popup-modal.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MaterialModule } from './ui-base.material.module';
import { FormsModule } from '@angular/forms';
import { ErrorDivComponent } from './components/error-div/error-div.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { GridComponent } from './components/grid/grid.component';
import { ActionRenderComponent } from './components/action-render.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationViewAllComponent } from './components/notification-view-all/notification-view-all.component';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';


export function initApp(starter: StarterService) {
  return () => starter.init();
}
@NgModule({
  imports: [
    CommonModule,
    MatDatepickerModule,
    RouterModule.forChild([
      { path: 'error', pathMatch: 'full', component: ErrorPageComponent }
    ]),
    MaterialModule,
    FormsModule,
    BrowserAnimationsModule,
    AgGridModule.withComponents([]),
    NgxChartsModule,
    NgbModule,
    NgbPaginationModule
  ],
  declarations: [
    SpinnerComponent,
    FileUploadComponent,
    PopupModalComponent,
    DatepickerComponent,
    ErrorDivComponent,
    ErrorPageComponent,
    GridComponent,
    ActionRenderComponent,
    ChartComponent,
    NotificationComponent,
    NotificationViewAllComponent,
    ConfirmationPopUpComponent
  ],
  exports: [
    SpinnerComponent,
    MatDatepickerModule,
    DatepickerComponent,
    MaterialModule,
    ErrorDivComponent,
    ErrorPageComponent,
    GridComponent,
    FileUploadComponent,
    ActionRenderComponent,
    ChartComponent,
    NotificationComponent,
    NotificationViewAllComponent,
    ConfirmationPopUpComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [StarterService]
    }
  ],
})
export class UiBaseModule { }

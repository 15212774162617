import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHttpService } from '../../services/error-http.service';

@Component({
  selector: 'base-error-div',
  templateUrl: './error-div.component.html',
  styleUrls: ['./error-div.component.scss']
})
export class ErrorDivComponent implements OnInit {

  errMsg: string;
  validationMsges;

  constructor(private errSer: ErrorHttpService, private router: Router) { }

  ngOnInit(): void {

    this.errSer.errMsgSec.subscribe(errData => {
      if (errData.msg instanceof Array) {
        this.validationMsges = errData.msg;
        if (errData.msg) this.setFocus('hiddenField');
      } else {
        this.errMsg = errData.msg
        if (errData.msg) this.setFocus('hiddenField');
      }
    });

    this.router.events.subscribe(val => {
      this.clearMsg();
    })

    this.errSer.clearPopUperrorMsgFlag.subscribe(val => {
      if (val) {
        this.clearMsg();
      }
    })

  }

  clearMsg() {
    this.errMsg = "";
    this.validationMsges = 0;
    this.errSer.errMsgSec.next("");
  }

  setFocus(elementId) {
    setTimeout(() => {
      document.getElementById(elementId).focus();
    }, 100);
  }


}
import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ManageWindowService } from './../../services/manage-window.service';
import { AddWindow } from './../../typedef/api-model';
import { HomeLandingConstants } from './../../constants/home-landing.constant';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'hrs-ui-add-window',
  templateUrl: './add-window.component.html',
  styleUrls: ['./add-window.component.scss']
})

// TODO : Validations
// - Add window button should be disabled if any request is open.
// - Add window -> Start date should not be less than todays date.
// - Add window -> Start date should not be greater than end date.
// - On edit window -> check if end date is less than start date
// - After window has been closed, Need to add functionality in my skills page,
//   to make all fields readonly. use SkillCdTable key for the same

export class AddWindowComponent implements OnInit {
  addWindowData: AddWindow = new AddWindow();
  startDate;
  endDate;
  HomeLandingConstants = HomeLandingConstants;
  windowTypes = [];
  windowList = []
  userIds: string;
  validUsers;
  invalidUserIds = [];

  //Flags
  isEditAction = false;
  isInvalidIdsPresent = false;
  isValidIdsPresent = false;
  isClosingRemarkVisible = false;
  isTypeAdHoc = false;
  startDateGreaterThanEndDate = false;

  constructor(private datePipe: DatePipe, public router: Router,
    public manageWindowService: ManageWindowService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.addWindowData.action = "Add";
    this.startDate = this.addWindowData?.startDate ?
      new Date(this.addWindowData.startDate) : undefined
    this.endDate = this.addWindowData?.endDate ?
      new Date(this.addWindowData.endDate) : undefined;
    this.manageWindowService.getManageWindowTypes().subscribe(data => {
      this.windowTypes = data;
    })
    this.getWindowYearsList()
  }

  onStartDateSelect(event) {
    const date = this.datePipe.transform(event?.selectedDate, HomeLandingConstants.DATE_FORMAT);
    this.addWindowData.startDate = date;
  }

  onEndDateSelect(endDate) {
    const date = this.datePipe.transform(endDate?.selectedDate, HomeLandingConstants.DATE_FORMAT);
    this.addWindowData.endDate = date;
    let todaysDate = new Date();
    if (endDate?.selectedDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0) ||
      endDate?.selectedDate.setHours(0, 0, 0, 0) < todaysDate.setHours(0, 0, 0, 0)
    ) {
      this.isClosingRemarkVisible = true;
    } else {
      this.isClosingRemarkVisible = false;
    }
  }

  validateAndAdd() {
    let userIds = [];
    userIds = this.userIds.split(',')
    this.manageWindowService.searchUsers(userIds).subscribe(data => {
      if (data?.validUserMap) {
        this.isValidIdsPresent = true;
        if (this.validUsers) {
          this.validUsers = { ...this.validUsers, ...data.validUserMap };
        } else {
          this.validUsers = data.validUserMap;
        }
        this.addWindowData.userIds = Object.keys(this.validUsers);
      }
      if (data?.invalidUserIds && data.invalidUserIds.length > 0) {
        this.isInvalidIdsPresent = true;
        this.userIds = data.invalidUserIds;
      } else {
        this.isInvalidIdsPresent = false;
        this.userIds = '';
      }
    })
  }

  deleteUser(key, value) {
    const dialogRef = this.dialog.open(PopupModalComponent, {
      width: '600px',
      data: {
        message: ["Are you sure you want to remove " + value + "?"],
        button: ['OK', 'Cancel']
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res == 'OK') {
        delete this.validUsers[key];
        this.addWindowData.userIds = Object.keys(this.validUsers);
      }
    })

  }


  changeWindowType() {
    (this.addWindowData.type == 'AdHoc') ? this.isTypeAdHoc = true : this.isTypeAdHoc = false;
  }
  save() {
    let startDate = this.datePipe.transform(this.addWindowData?.startDate, "yyyy-MM-dd");
    let endDate = this.datePipe.transform(this.addWindowData?.endDate, "yyyy-MM-dd");
  
    if (startDate >= endDate) {
      this.startDateGreaterThanEndDate = true;
    }
    else {
      this.startDateGreaterThanEndDate = false;
      this.manageWindowService.addOrEditWindow(this.addWindowData).subscribe(data => {
        this.router.navigate(['./manage-window']);
      })
    }
  }

  getWindowYearsList() {
    let date = new Date();
    let currentYear = date.getFullYear();
    let oneYearFromNow = date.getFullYear() + 1;
    let twoYearFromNow = date.getFullYear() + 2;

    let currentFinancialYear = currentYear + "-" + oneYearFromNow;
    let nextFinancialYear = oneYearFromNow + "-" + twoYearFromNow;
    this.windowList.push(currentFinancialYear, nextFinancialYear)
  }

  dismiss() {
    this.router.navigate(['./manage-window']);
  }
}

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, Optional } from '@angular/core';

@Component({
  selector: 'hrs-ui-chart-popup',
  templateUrl: './chart-popup.component.html',
  styleUrls: ['./chart-popup.component.scss']
})
export class ChartPopupComponent implements OnInit {
  chartData = [];
  showLegend = true;
  explodeSlices = false;
  showLabels = true;
  gradient = false;
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  xAxisLabel = 'Employee Experience';
  showYAxisLabel = true;
  lTitle = "Employee Count";

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#008080', '#800000']
  };
  constructor(@Optional() public dialogRef: MatDialogRef<ChartPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.chartData = this.data.chartData;
  }

  onSelect(event) {

  }

  groupByExperience(val) {
    if (val == '0-5') {
      return '0 to 5';
    } else if (val == '6-10') {
      return '6 to 10';
    } else if (val == '11-15') {
      return '11 to 15';
    } else if (val == '16-20') {
      return '16 to 20';
    } else if (val == '21-25') {
      return '21 to 25';
    } else if (val == 'More than 26') {
      return 'More than 25';
    }
  }

  close() {
    this.dialogRef.close(null);
  }

}

export class UploadComponentConfig {
  reset?: boolean;
  accept?: string;
  inputFieldId?: string;
  permissibleSize?: number;
  permissibleExtensions?: Array<String>;
  isDisabled?: boolean
}

export class UploadEmitEvent {
  continueUpload: boolean;
  fileEvent: any;
}

export class BaseChartModel {
  chartType: string;
  chartTitle: string;
  showGradient: boolean;
  chartwidth: number;
  chartHeight: number;
  animations?: boolean;
  legend?: boolean;
  legendTitle: string;
  showLegend: boolean;
  showGridLines: boolean;
  data;
  colorScheme;
}
export class BarChartModel extends BaseChartModel {

  barPadding: number;
  groupPadding: number;
  xAxisLabel: string;
  yAxisLabel: string;

}

export class PieChartModel extends BaseChartModel {
  explodeSlices: boolean;
  showLabels: boolean;
  doughnut: boolean;

}

export class DoughnutChartModel extends BaseChartModel {
  showLablesInPieChart: boolean;
  doughnut = true;
}

import { NgForm } from '@angular/forms';
import { ConfirmationPopUpComponent } from 'libs/ui-base/src/lib/components/confirmation-pop-up/confirmation-pop-up.component';
import { SkillConstants } from './../../../constants/skill.constant';
import { UploadDocComponent } from './../../upload-doc/upload-doc.component';
import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { ViewUploadedDocsComponent } from '../../view-uploaded-docs/view-uploaded-docs.component';
import { MatDialog } from '@angular/material/dialog';
import { SkillTreeComponent } from './../../../../components/skill-tree/skill-tree.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { SkillService } from './../../../services/skill.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserSkills, AddSkillRequest } from './../../../../typedef/api-model';
import _ from 'lodash';
import { AppService } from 'apps/hr-skills/src/app/app.component';
import { SkillDetailsComponent } from '../../skill-details/skill-details.component';

@Component({
  selector: 'hrs-ui-tools-softwares',
  templateUrl: './tools-softwares.component.html',
  styleUrls: ['./tools-softwares.component.scss'],
})
export class ToolsSoftwaresComponent implements OnInit {
  toolsAndSoftwareData: UserSkills = new UserSkills();
  addSkillObj: AddSkillRequest = new AddSkillRequest();
  @ViewChild('toolsAndSoftwaresForm') public toolsAndSoftwaresForm: NgForm;
  toolsAndSoftExp;
  userId;
  uploadDocObj;
  addDocObj = {};
  uploadedFiles = [];
  isSupervisorAction = false;
  isViewMode = false;
  isSkillSaved: boolean;
  locallyAddedSkills = [];
  preSelectedSkills = [];
  selectedItems = [];
  dropdownSettings = {};
  isHr: boolean = false;
  arrDropDownNumbers = [];
  SkillConstants = SkillConstants;
  constructor(
    private skillService: SkillService,
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.arrDropDownNumbers = Array(30)
      .fill(0)
      .map((x, i) => i + 1);
    console.log(this.arrDropDownNumbers);
    this.activatedRoute.queryParams.subscribe((queryparams) => {
      if (queryparams?.userId) {
        this.isHr = queryparams?.isHr;
        this.userId = queryparams?.userId;
        this.isViewMode = queryparams?.actionType == 'view' ? true : false;
        this.isViewMode = this.isHr ? false : this.isViewMode;
        this.isSupervisorAction = this.isHr ? false : true;
        this.getSkillListAndRatings();
      } else {
        this.authService.getLoggedInUser().subscribe((res) => {
          if (res && res.userId) {
            this.userId = res.userId;
            console.log(this.userId);
            this.getSkillListAndRatings();
          }
        });
      }
    });
  }

  async getSkillListAndRatings() {
    this.getToolsAndSoftwareData();
    this.toolsAndSoftExp = await this.skillService
      .getToolsAndSoftwareRatings()
      .toPromise();
  }

  addToolsOrSoftwares() {
    this.preSelectedSkills = this.skillService.getPreSelectedSkills(
      this.toolsAndSoftwareData
    );
    let dialogData = {
      accordian: SkillConstants.TOOLSANDSOFTWARE,
      selectedSkills: this.preSelectedSkills,
    };

    this.skillService
      .invokeDialog(SkillTreeComponent, SkillConstants.WIDTH_50EM, dialogData)
      .afterClosed()
      .subscribe((newSkills) => {
        this.skillService.fetchAndAddSkills(
          newSkills,
          this.toolsAndSoftwareData
        );
      });
  }

  getToolsAndSoftwareData() {
    this.skillService
      .getToolsAndSoftSkills(this.userId)
      .subscribe((toolsAndSoftwareData) => {
        this.toolsAndSoftwareData = toolsAndSoftwareData;
        if (this.toolsAndSoftwareData.userSkills) {
          if (
            !this.isSupervisorAction &&
            this.toolsAndSoftwareData.userSkills.status != '1000'
          ) {
            this.isViewMode = true;
          } else if (
            this.isSupervisorAction &&
            this.toolsAndSoftwareData.userSkills.status == '9000'
          ) {
            this.isViewMode = true;
          }
        }
        if (!this.appService.getIsEditWindowAvailable()) {
          this.isViewMode = true;
        }
        this.isViewMode = this.isHr ? false : this.isViewMode;
      });
  }

  changeToolOrSoftExp(index) {
    this.toolsAndSoftwareData.userSkills.skills[index].profiencyType =
      SkillConstants.SCALE;
    // if (actionFrom === 'user') {
    //   this.toolsAndSoftwareData.userSkills.skills[index].profiencyValue = e.target.value
    // } else {
    //   this.toolsAndSoftwareData.userSkills.skills[index].supervisorRating = e.target.value
    // }
  }

  saveToolsAndSoftwares(isSubmitCall?) {
    console.log('DIRTY ', this.toolsAndSoftwaresForm.dirty);
    if (this.toolsAndSoftwaresForm.valid) {
      if (this.toolsAndSoftwareData?.userSkills?.skills.length) {
        this.toolsAndSoftwareData.userSkills.userId = this.userId;
        this.addSkillObj.userType = this.getUserType();
        this.addSkillObj.userId = this.toolsAndSoftwareData.userSkills.userId;
        this.addSkillObj.skills = this.toolsAndSoftwareData.userSkills.skills.map(
          (v) => ({
            ...v,
            competency: SkillConstants.TOOLSANDSOFTWARE_COMPETENCY,
          })
        );
        for (let i = 0; i < this.addSkillObj.skills.length; i++) {
          this.addSkillObj.skills[i] = _.pick(
            this.addSkillObj.skills[i],
            this.skillService.addSkillReqObjFields
          );
        }
        if (this.isSupervisorAction || this.isHr) {
          this.reviewSkillCall(this.addSkillObj);
        } else {
          this.addSkillCall(this.addSkillObj, isSubmitCall);
        }
      }
    } else {
      this.toolsAndSoftwaresForm.control.markAllAsTouched();
    }
  }

  addSkillCall(data, isSubmitCall) {
    if (!isSubmitCall) {
      this.skillService.addSkills(data).subscribe((res) => {
        if (res.id) {
          this.dialog
            .open(PopupModalComponent, {
              width: SkillConstants.WIDTH_500PX,
              position: { top: SkillConstants.WIDTH_20PX },
              data: {
                message: ['', SkillConstants.SAVE_SKILL_MSG],
                button: [SkillConstants.OK],
              },
              disableClose: false,
            })
            .afterClosed()
            .subscribe((response) => {
              this.toolsAndSoftwaresForm.form.markAsPristine();
              this.isSkillSaved = true;
              this.getToolsAndSoftwareData();
            });
        }
      });
    } else {
      this.skillService.addSkills(data).subscribe((res) => {
        if (res.id) {
          this.isSkillSaved = true;
          this.getToolsAndSoftwareData();
        }
      });
    }
  }

  reviewSkillCall(data) {
    this.skillService.reviewSkills(data).subscribe((res) => {
      if (res.id) {
        this.dialog
          .open(PopupModalComponent, {
            width: SkillConstants.WIDTH_500PX,
            position: { top: SkillConstants.WIDTH_20PX },
            data: {
              message: ['', SkillConstants.SKILL_REVIEW_SAVE_MSG],
              button: [SkillConstants.OK],
            },
            disableClose: false,
          })
          .afterClosed()
          .subscribe((response) => {
            this.toolsAndSoftwaresForm.form.markAsPristine();
            this.isSkillSaved = true;
            this.getToolsAndSoftwareData();
          });
      }
    });
  }

  deleteSkill(index) {
    const data = this.dialog.open(PopupModalComponent, {
      width: SkillConstants.WIDTH_350PX,
      data: {
        message: [SkillConstants.DELETE_SKILL_MSG],
        button: [SkillConstants.YES, SkillConstants.CANCEL],
      },
    });

    data.afterClosed().subscribe((res) => {
      if (res == SkillConstants.YES) {
        if (
          !this.locallyAddedSkills.includes(
            this.toolsAndSoftwareData.userSkills.skills[index]?.skillCd
          )
        ) {
          const payload = {
            skillCd: this.toolsAndSoftwareData.userSkills.skills[index]
              ?.skillCd,
            userId: this.toolsAndSoftwareData.userSkills?.userId,
          };
          this.skillService.deleteSkill(payload).subscribe((res) => {
            if (res.result === 'success') {
            }
          });
        }
        this.toolsAndSoftwareData.userSkills.skills.splice(index, 1);
      }
    });
  }
  showSkillDetails(skillCd, type?) {
    //   this.router.navigate(['skill/skill-details'], {
    //   queryParams: { skillCd: skillCd },
    // });
    const dialogRef = this.dialog.open(SkillDetailsComponent, {
      width: '600px',
      height: '600px',
      data: { skillCd: skillCd, type:type },
    });
    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
    });
    return dialogRef;
  }
  fetchDeleteButtonState(toolOrSoftware) {
    let currentUser;
    if (this.isSupervisorAction) {
      currentUser = SkillConstants.SUPERVISOR;
    } else if (this.isHr) {
      currentUser = SkillConstants.HUMAN_RESOURCES;
    } else {
      currentUser = SkillConstants.EMPLOYEE;
    }
    if (currentUser == SkillConstants.HUMAN_RESOURCES) {
      return true;
    }

    return (
      !this.isViewMode &&
      (!toolOrSoftware.addedBy || toolOrSoftware.addedBy == currentUser)
    );
  }
  getUserType() {
    let userType;
    if (this.isHr && !this.isSupervisorAction) {
      userType = SkillConstants.HUMAN_RESOURCES;
    }
    if (this.isSupervisorAction && !this.isHr) {
      userType = SkillConstants.SUPERVISOR;
    }
    if (!this.isHr && !this.isSupervisorAction) {
      userType = 'employee';
    }
    return userType;
  }
}

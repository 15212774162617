//import { environmentDetails } from './common.static-variables';
//const data = environmentDetails;
// data.domainUrl = "http://localhost:8080",

export const environment = {
  domainUrl: "",
  production: false,
  adauth: {
    clientId: "c1a56fed-450e-4163-b91b-c271dc9f34a9",
    directoryId: "b46bcce8-fa4b-4d50-a419-da7f5c6dc039"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

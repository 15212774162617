import { SkillComponent } from './components/skill-review/skill/skill.component';
import { UiBaseModule } from 'libs/ui-base/src/lib/ui-base.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkillDetailsComponent } from './components/skill-details/skill-details.component';
import { SkillReviewComponent } from './components/skill-review/skill-review.component';
import { ViewUploadedDocsComponent } from './components/view-uploaded-docs/view-uploaded-docs.component';
import { UploadDocComponent } from './components/upload-doc/upload-doc.component';
import { FormsModule } from '@angular/forms';
import { SkillReviewGridComponent } from './components/skill-review-grid/skill-review-grid.component';
import { HrSkillGridComponent } from './components/hr-skill-grid/hr-skill-grid.component';
import { EmployeeSearchComponent } from './components/employee-search/employee-search.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToolsSoftwaresComponent } from './components/skill-review/tools-softwares/tools-softwares.component';
import { FunctionalExpComponent } from './components/skill-review/functional-exp/functional-exp.component';
import { GeographicalExpComponent } from './components/skill-review/geographical-exp/geographical-exp.component';
import { LanguagesComponent } from './components/skill-review/languages/languages.component';

@NgModule({
  declarations: [SkillDetailsComponent, SkillReviewComponent, ViewUploadedDocsComponent, UploadDocComponent, SkillReviewGridComponent, HrSkillGridComponent, EmployeeSearchComponent, SkillComponent, ToolsSoftwaresComponent, FunctionalExpComponent, GeographicalExpComponent, LanguagesComponent],
  imports: [
    CommonModule,
    FormsModule,
    UiBaseModule,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class SkillModule { }

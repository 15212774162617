import { GridComponent } from './../../../../../../../libs/ui-base/src/lib/components/grid/grid.component';
import { GridService } from './../../../../../../../libs/ui-base/src/lib/services/grid.service';
import { GridModel } from 'libs/ui-base/src/lib/typedef/common-models';
import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { getEmployeeSearchData } from '../../constants/skill-grid.constants';

@Component({
  selector: 'hrs-ui-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss']
})
export class EmployeeSearchComponent implements OnInit {

  agGridConst: GridModel;
  searchText;
  @ViewChild('baseGrid') child: GridComponent;
  skillCd;
  skillsMap = {
    "angular2": "4002",
    "angular1": "4001",
    "reactjs": "4003",
    "java": "4004",
    "c#": "4005"
  }
  constructor(private gridService: GridService) { }

  ngOnInit(): void {
    this.agGridConst = getEmployeeSearchData(this.skillCd);
  }


  fetchEmployeeList() {

    if (this.skillsMap[this.searchText]) {
      this.skillCd = this.skillsMap[this.searchText]
    } else {
      this.skillCd = '';
    }
    console.log("skillCd", this.skillCd)
    this.agGridConst = getEmployeeSearchData(this.skillCd);
    this.child.refreshCell();
    this.gridService.setRefreshGridSubject(true);


  }

  clearFlags() { }


}

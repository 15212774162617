import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';
import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { ManageWindowService } from '../../services/manage-window.service';
import { SkillConstants } from '../../skill/constants/skill.constant';

@Component({
  selector: 'base-action-render',
  template: `
    <!-- <span>
      <a style="color: blue; cursor: pointer" (click)="view(params)" title="View"><i class="fa fa-eye actionicon-color" aria-hidden="true"></i></a>
    </span> -->
    <!-- <span *ngIf="editMode"> | </span>
    <span *ngIf="editMode">
      <a style="color: blue; cursor: pointer" (click)="edit(params)" title="Edit"><i class="fa fa-pencil actionicon-color" aria-hidden="true"></i></a>
    </span> -->
    <!-- <span *ngIf="isWindowValid"> | </span> -->
    <span *ngIf="isWindowValid">
      <a style="color: red; cursor: pointer" (click)="deleteWindow(params)" title="Delete"><i class="fa fa-trash actionicon-color" aria-hidden="true"></i></a>
    </span>
    <span *ngIf="!isWindowValid">NA</span>

    `,
  styles: []
})
export class ManageWindowActionRenderComponent implements OnInit, AgRendererComponent {

  params: any;
  public actionType: string;
  public editMode: boolean;
  public viewMode: boolean;
  isWindowValid: boolean = false; 
  public isAddWindowDisabled: EventEmitter<boolean> = new EventEmitter();
  constructor(private router: Router, private service : ManageWindowService, private dialog: MatDialog) { }

  ngOnInit(): void { }

  deleteWindow(params: any) {
    const popupMsg = 'Do you want to delete the selected window ?';
    const button = [SkillConstants.YES, SkillConstants.CANCEL];
    const dialogRef = this.openInfoPopup(popupMsg, button);
    dialogRef.afterClosed().subscribe((res) => {
      if (res == SkillConstants.YES) {
        this.service.deleteWindow(params.data.id).subscribe((res) => {
          this.openInfoPopup('Window deleted successfully.');
          this.reloadCurrentPage()
        });
      }
    });
  }

  openInfoPopup(popupMsg, buttons?) {
    if (!buttons) {
      buttons = [SkillConstants.OK];
    }
    const dialogRef = this.dialog.open(PopupModalComponent, {
      width: SkillConstants.WIDTH_350PX,
      data: {
        message: [popupMsg],
        button: buttons,
      },
    });
    return dialogRef;
  }

  agInit(params): void {
    this.params = params;
    //logic to decide editMode / viewMode
    if (this.params.data.status == 'Open') {
      this.editMode = true;
      this.viewMode = true;
      this.isAddWindowDisabled.emit(false);
    }
    // Window is valid
    if(this.checkWindowValidity(params)) {
      this.isWindowValid = true;
    }
  }

  checkWindowValidity(params) {
    const endDt = new Date(params.data.endDate);
    const today = new Date();
    return !(today > endDt);
  }

  refresh(): boolean {
    return true;
  }

  view(params) {
    this.navigate(params, 'view');
  }

  edit(params) {
    this.navigate(params, 'edit');
  }

  navigate(params, actionType?: string) {

    let data = params.data;
    let navigationDetails = params.nextPageNavigation;
    let navigation = {
      url: null,
      parameter: null,
      readOnly: false
    };

    navigation.url = navigationDetails.url;
    navigation.parameter = navigationDetails.parameter;
    // navigation.readOnly = navigationDetails.readOnly;

    let queryParamsObj =
      (navigation.parameter &&
        this.getParameterData(navigation.parameter, data)) ||
      {};

    if (actionType) {
      queryParamsObj['actionType'] = actionType;
    }

    //When you want to redirect on diffrent Url for View
    if ('viewUrl' in navigationDetails && actionType == 'view') {
      this.router.navigate([navigationDetails.viewUrl], {
        queryParams: queryParamsObj
      });
    } else {
      this.router.navigate([navigation.url], { queryParams: queryParamsObj });
    }
  }

  getParameterData(field = '', data) {
    let fields = field.split(';');
    let queryParamsObj = {};
    fields.forEach(element => {
      queryParamsObj[element] = this.resolveObject(element, data);
    });
    return queryParamsObj;
  }

  resolveObject(path, obj = self, separator = '.') {
    let properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  }

  reloadCurrentPage() {
    window.location.reload();
   }

}

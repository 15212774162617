import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UiEnvSetupService {

  private envParams: EnvParams;
  sitekey = new ReplaySubject<any>(1);
  constructor(private http: HttpClient,
    @Inject('Environment') private parentEnvironment: any) {
  }
  loadEnv(): Observable<any> {
    return this.http.get(this.parentEnvironment.uiEnvVariables).pipe(tap(res => {
      this.envParams = res;
      this.sitekey.next(res['recaptchaSiteKey']);
      return res;
    }));
  }

  getEnv(): EnvParams {
    return this.envParams;
  }
}
interface EnvParams {
  // inteface will be revamped as per the UI env requirmet
  oidcTenant: string;
  oidcClientId: string;
  recaptchaSiteKey: string;
  loginOffline: boolean;
  ssePollInterval: number;
}

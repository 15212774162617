export class SkillConstants {
  // Endpoint Url
  public static GET_SKILL_DETAILS = '/api/hrskills/skills/skillDetail/';
  public static GET_PROFICIENCY_RATING =
    '/api/hrskills/skills/skillProfiency/Rating';
  public static GET_PROFICIENCY_EXP =
    '/api/hrskills/skills/skillProfiency/Experience';
  public static GET_PROFICIENCY_SCALE =
    '/api/hrskills/skills/skillProfiency/Scale';
  public static GET_PROFICIENCY_LANGUAGE =
    '/api/hrskills/skills/skillProfiency/Language-Profilency';
  public static GET_SKILL_LIST = '/api/hrskills/skills/';
  public static DOWNLOAD_SKILL_DOC = '/api/hrskills/skills/download/';
  public static DELETE_SKILL_DOC = '/api/hrskills/skills/delete/';
  public static ADD_SKILLS = '/api/hrskills/skills/addskills';
  public static REVIEW_SKILLS = '/api/hrskills/skills/review';
  public static UPDATE_STATUS = '/api/hrskills/skills/status/update';
  public static DELETE_SKILL = '/api/hrskills/skills/deleteskill';
  public static GET_SKILL_REVIEW_GRID = '/api/hrskills/skills/review/list';
  public static GET_HR_SKILL_GRID = '/api/hrskills/skills/hrreview/list';
  public static EXPORT_HR_SKILL_GRID = '/api/hrskills/skills/hrreview/export';
  public static EMPLOYEE_SEARCH = '/api/hrskills/skills/users/list/';
  public static SKILL_DOMAIN = '/api/hrskills/master/domain';
  public static GET_USER_SKILLS = '/api/hrskills/skills/getuserskills';
  public static GET_FUNCTIONAL_SKILLS =
    '/api/hrskills/skills/getfunctionSkills';
  public static GET_GEOGRAPHICAL_SKILLS =
    '/api/hrskills/skills/getgeographicalskills';
  public static GET_LANGUAGES_SKILLS = '/api/hrskills/skills/getlanguageskills';
  public static GET_TOOLS_AND_SOFT_SKILLS =
    '/api/hrskills/skills/gettoolsandsoftwareskills';

  //Routes
  public static SKILL_REVIEW_ROUTE = 'skill/skill-review';
  public static SKILL_REVIEW_GRID_ROUTE = 'skill/skill-review-grid';

  //Headers
  public static MY_SKILL_REVIEW_HEADER = 'My Skills';
  public static EMPLOYEE_SKILL_REVIEW_HEADER = 'Employee Skills Review';

  //Keywords
  public static TOOLSANDSOFTWARE = 'ToolsAndSoftware';
  public static FUNCTIONAL = 'functional';
  public static GEOGRAPHICAL = 'geoGraphical';
  public static LANGUAGES = 'Languages';
  public static SKILL_COMPETENCY = 'Skills';
  public static GEOGRAPHICAL_COMPETENCY = 'Geographical Experience';
  public static FUNCTIONAL_COMPETENCY = 'Functional Experience';
  public static TOOLSANDSOFTWARE_COMPETENCY = 'Tools/Software';
  public static FUNCTIONS = 'Functions';
  public static GEOGRAPHY = 'Geography';

  public static RATING = 'Rating';
  public static SCALE = 'Scale';
  public static LANGUAGE_PROFICIENCY = 'Language-Profilency';
  public static SKILL_REMARK = 'Skill Remark';
  public static SELF_RATING_REMARK = 'Self Rating Remarks';

  public static EXPERIENCE = 'Experience';
  public static EMP_COUNT = 'Employee Count';
  public static WIDTH_50EM = '50em';
  public static WIDTH_500PX = '500px';
  public static WIDTH_350PX = '350px';
  public static WIDTH_20PX = '20px';
  public static HEIGHT_90_PERCENT = '90%';
  public static CANCEL = 'Cancel';
  public static YES = 'Yes';
  public static OK = 'OK';
  public static EMPLOYEE = 'employee';
  public static HUMAN_RESOURCES = 'humanResources';
  public static SUPERVISOR = 'supervisor';

  //Messages
  public static SUPERVISOR_UPDATE_MSG =
    'Your review cannot be edited once you submit it. Do you want to proceed?';
  public static EMPLOYEE_UPDATE_MSG =
    'Your skill details cannot be edited once submitted for IS review. Do you want to proceed?';
  public static SAVE_SKILL_MSG =
    'Your skill details will be saved for later editing.Click Submit to send skill details for IS review.';
  public static DELETE_SKILL_MSG =
    'Are you sure you want to delete this skill?';
  public static SKILL_REVIEWED_MSG = 'Skills has been reviewed successfully';
  public static SKILL_SENT_FOR_REVIEW_MSG =
    'Skills has been sent to supervisor for review.';
  public static SKILL_REVIEW_SAVE_MSG =
    'Your review will be saved for later editing. Click Submit to submit review.';
  public static SAVE_SKILL_BEFORE_SUBMIT_MSG =
    'Please save skill data before submit';
  public static SAVE_TOOLS_AMD_SOFT_BEFORE_SUBMIT_MSG =
    'Please save tools & Software data before submit';
  public static SAVE_FUNCTIONAL_EXP_BEFORE_SUBMIT_MSG =
    'Please save functional experience data before submit';
  public static SAVE_GEOGRAPHICAL_EXP_BEFORE_SUBMIT_MSG =
    'Please save geographical experience data before submit';
}

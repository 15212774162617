import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { empty, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorHttpService } from './error-http.service';
import { SpinnerService } from './spinner.service';
import { UtilityService } from './utility.service';
import { CustomHttpHeaders } from './api-client';
import { map, catchError, finalize } from 'rxjs/operators';

@Injectable()
export class Interceptor implements HttpInterceptor {

  httpReqStack: HttpRequest<any>[] = [];
  constructor(private router: Router,
    private authService: AuthService,
    @Inject('Environment') private parentEnvironment: object,
    private errorHttpService: ErrorHttpService,
    private injector: Injector,
    private spinnerService: SpinnerService,
    private msgService: UtilityService) { }

  //Start spinning request
  addToStack(req: HttpRequest<any>) {
    // pushes req into the stack of spinner
    this.httpReqStack.push(req);
    this.spinnerService.isLoading.next(true);
    this.spinnerService.isTransperent.next(true);
  }


  // clears the stack for spinner once error/response comes
  removeFromStack(req: HttpRequest<any>) {
    const reqIndex = this.httpReqStack.indexOf(req);
    if (reqIndex >= 0)
      this.httpReqStack.splice(reqIndex, 1);
    this.spinnerService.isLoading.next(this.httpReqStack.length > 0);
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const header = request.headers;
    let otherParams = null;
    let isApiClientCall = false;

    if (request.headers instanceof CustomHttpHeaders) {
      if (request.headers.otherParams) {
        otherParams = request.headers.otherParams;
      }
      isApiClientCall = true;
    }
    request.headers.delete('otherParams');
    let updatedRequest = request.clone({ withCredentials: true});

    if (this.authService.getJwtToken()) {
      updatedRequest = request.clone({ withCredentials: true, 
       setHeaders: { Authorization: `Bearer ${this.authService.getJwtToken()}` }
       });
    }
    this.addToStack(updatedRequest);

    return next.handle(updatedRequest).pipe(
      map((event: HttpEvent<any>) => {
        // if (event instanceof HttpResponse) {
        // }
        if(event['status'] == 200) {
          this.authService.setServerDown(false);
        }
        return event;
      }),

      catchError((error: HttpErrorResponse) => {
        //this.removeFromStack(updatedRequest);
        if (error instanceof HttpErrorResponse) {
          if (isApiClientCall) {

            if (otherParams != null && otherParams.customExemption &&
              otherParams.customExemption.find(x => x == error.status)) {
              // handling error by developer .. 
              return throwError(error);
            }
            else {
              // error will be shown in error-section below header
              if (error.status == 401) {
                if (error.message == 'Http failure response for http://localhost:8080/api/login: 401 OK') {
                  this.errorHttpService.handleErrorDiv(error, null, "Invalid Login Credentials , Please try again");
                }
                else {
                  this.errorHttpService.handleErrorDiv(error, null, this.getMessageResponse(error));
                }
              }
              else if (error.status == 0) {
                this.authService.setServerDown(true);
                return throwError(error);
              }
              else {
                this.errorHttpService.handleErrorDiv(error, null, this.getMessageResponse(error));
              }
            }
          }
          else {
            // error will be shown on error page (next page navigation) 
            if (error.status == 401) {
              alert("You session has expirted");
              this.router.navigate(["login"]);
            } 
            else {
              this.errorHttpService.handleErrorPage(error, null, this.getMessageResponse(error));
              this.router.navigateByUrl('error');
              this.spinnerService.isLoading.next(false)

            }
          }
          return empty();
        }
      }),
      finalize(() => {
        this.removeFromStack(updatedRequest);
      })
    );
  }

  redirectToLogin() {
    // const authService = this.injector.get(AuthService)
    // authService.login();
    this.router.navigate(['/login']);
  }
  getMessageResponse(error) {
    let msg: any;
    switch (error.status) {
      case 400:
        msg = 'Http error: Bad Request'
        //"this.msgService.getMessage(GlobalCodesConstatns.HTTPERROR, GlobalCodesConstatns.BAD_REQUEST);"
        break;
      case 403:
        msg = 'You are not authorized to access this page, please contact your system admin.'
        //"this.msgService.getMessage(GlobalCodesConstatns.HTTPERROR, GlobalCodesConstatns.FORBIDDEN_REQUEST);"
        break;
      case 404:
        msg = 'Resource not found'
        //"this.msgService.getMessage(GlobalCodesConstatns.HTTPERROR, GlobalCodesConstatns.NULL_RESOURCE);"
        break;
      case 500:
        msg = 'Oops! something went wrong. Please contact your system administrator'
        // "this.msgService.getMessage(GlobalCodesConstatns.HTTPERROR, GlobalCodesConstatns.SERVER_ERROR);"
        break;
      case 422:
        msg = this.msgService.getValidationMessageArray(error);
        break;
      case 401:
        msg = 'You are not authenticated to access this page';
        break;
      case 0:
        msg = 'HR Skills is currently down for maintenance. Please try after sometime.'
        break;
      default:
      // msg = '* This - { ' + error.status + ' } error is not being handled , Please contact system Admin ';
    }
    return msg;
  }
}

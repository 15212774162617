import { ManageWindowActionRenderComponent } from './components/manage-window/manage-window-action-render.component';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterHistoryService } from 'libs/ui-base/src/lib/services/router-history.service';
import { Interceptor } from 'libs/ui-base/src/lib/services/http.interceptor';
import { ApiClient } from 'libs/ui-base/src/lib/services/api-client';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UiBaseModule } from 'libs/ui-base/src/lib/ui-base.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManageProfileComponent } from './components/manage-profile/manage-profile.component';
import { SkillModule } from '../app/skill/skill.module';
import { SkillTreeComponent } from './components/skill-tree/skill-tree.component';
import { TreeviewModule } from 'ngx-treeview';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { Dashboard1Component } from './components/dashboard1/dashboard1.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ComboChartComponent, ComboSeriesVerticalComponent } from '../app/components/dashboard/combo-chart';
import { ChartPopupComponent } from './components/dashboard/chart-popup/chart-popup.component';
import { ManageWindowComponent } from './components/manage-window/manage-window.component';
import { AddWindowComponent } from './components/add-window/add-window.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: window["ADCONFIG"].clientId, 
      authority: 'https://login.microsoftonline.com/common', 
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

export function initApp(auth: AuthService, msalService: MsalService) {
  msalService.handleRedirectObservable().subscribe({
    next: (result: AuthenticationResult) => {
      if (result) {
        auth.setAuth(result);
      }
    },
    error: (error) => console.log(error)
  });
}

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, HomeComponent, LandingComponent,
    LoginComponent, ManageProfileComponent, SkillTreeComponent, DashboardComponent,Dashboard1Component,
    ComboChartComponent, ComboSeriesVerticalComponent, ChartPopupComponent,
    ManageWindowComponent, AddWindowComponent, ManageWindowActionRenderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UiBaseModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDatepickerModule,
    SkillModule,
    AgGridModule.withComponents([]),
    TreeviewModule.forRoot(),
    BrowserAnimationsModule,
    NgxChartsModule,
    NgbModule,
    NgbPaginationModule,
    MsalModule],
  providers: [
    { provide: 'Environment', 
      useValue: environment 
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ApiClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    RouterHistoryService,
    DatePipe,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initApp,
    //   multi: true,
    //   deps: [AuthService, MsalService]
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { UtilityService } from '../../services/utility.service';
import { Notification } from '../../typedef/common-models';

@Component({
  selector: 'base-notification-view-all',
  templateUrl: './notification-view-all.component.html',
  styleUrls: ['./notification-view-all.component.scss']
})
export class NotificationViewAllComponent implements OnInit {

  pageNo = 1;
  pagesize = 20;
  allNotificationData: Notification;


  constructor(
    private notificationService: NotificationService,
    private utility: UtilityService,
    @Optional() @Inject('systemNotification') private systemNotification,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetchNotifications();
  }
  fetchNotifications() {
    this.notificationService
      .getAllNotifications(this.pageNo, this.pagesize)
      .subscribe(data => {
        data['content'] = data['content'].map(element => ({
          ...element,
          template: this.getNotifiationHTML(element.action, element.params)
        }));
        data['content'] = data['content'].map(element => ({
          ...element,
          navigation: this.getNavigation(element.action, element.params)
        }));

        // this.allNotificationData = data;
        window.scrollTo(0, 0);
      });
  }

  getNotifiationHTML(action, actionParams) {
    let template;
    try {
      template = this.systemNotification[action].template;
      return this.utility.replaceStringwithValue(template, actionParams);
    } catch (error) {
      console.log('error', error, " for action >", action);
      template = this.systemNotification['not.found'].template
      return this.utility.replaceStringwithValue(template, actionParams);
    }
  }

  getNavigation(action, actionParams) {
    let template;
    try {
      template = this.systemNotification[action].navigation;
      return this.utility.replaceStringwithValue(template, actionParams);
    } catch (error) {
      template = this.systemNotification['not.found'].navigation;
      return this.utility.replaceStringwithValue(template, actionParams);
    }
  }

  onNotificationClick(id, route) {
    this.notificationService.markNotificationAsRead(id).subscribe(data => {
      this.fetchNotifications();
    });
    this.router.navigateByUrl(route);
  }

  pagination(page: number): void {
    this.pageNo = page;
    this.fetchNotifications();
  }

  setFocus(elementId) {
    setTimeout(() => {
      document.getElementById(elementId).focus();
    }, 100);
  }

}

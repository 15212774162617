import { GridModel } from 'libs/ui-base/src/lib/typedef/common-models';
import { Component, OnInit } from '@angular/core';
import { fetchSkillReviewGridData } from '../../constants/skill-grid.constants';


@Component({
  selector: 'hrs-ui-skill-review-grid',
  templateUrl: './skill-review-grid.component.html',
  styleUrls: ['./skill-review-grid.component.scss']
})
export class SkillReviewGridComponent implements OnInit {
  agGridConst: GridModel;

  constructor() { }

  ngOnInit(): void {
    this.setAggridConst();
  }

  setAggridConst() {
    this.agGridConst = fetchSkillReviewGridData();
  }

}

import { MatDialog } from '@angular/material/dialog';
import { HomeLandingConstants } from './../constants/home-landing.constant';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiClient } from 'libs/ui-base/src/lib/services/api-client';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  barWidth = 150;
  constructor(private http: HttpClient,
    @Inject('Environment') private parentEnvironment: object,
    private apiClient: ApiClient, private dialog: MatDialog) { }

  invokeDialog(component: any, width: string, data: any, height?: string,) {
    const dialogRef = this.dialog.open(component, {
      width: width,
      height: height ? height : '',
      data: data
    });
    return dialogRef;
  }

  getSkillData(filters) {
    return <any>this.apiClient.getWithParams(HomeLandingConstants.GET_SKILL_CHART_DATA_URL, filters);
  }

  getSubSkillData(competancyVal, parentSkillVal?, params?) {
    let allParams = {...params, competancyVal: competancyVal};
    if (parentSkillVal) allParams.parentSkillVal = parentSkillVal;
    return <any>this.apiClient.getWithParams(HomeLandingConstants.GET_SUB_SKILL_CHART_DATA_URL, allParams);
  }
  
  getSubSkillToolsData(competancyVal, parentSkillVal?, params?) {
    let allParams = {...params, competancyVal: competancyVal};
    if (parentSkillVal) allParams.parentSkillVal = parentSkillVal;
    return <any>this.apiClient.getWithParams(HomeLandingConstants.GET_SUB_SKILL_TOOLS_DATA, allParams);
  }

  getEmpCountAvgProfData(skillVal, filters) {
    var params = {...filters, skillName:skillVal};
    return <any>this.apiClient.getWithParams(HomeLandingConstants.GET_EMP_COUNT_AVG_PROF_DATA_URL, params);
  }

  getSubSkillProfData(skillVal, filters) {
    var params = {...filters, skillName: skillVal};
    return <any>this.apiClient.getWithParams(HomeLandingConstants.GET_SUB_SKILL_PROF_DATA_URL, params);
  }

  getToolsSoftProfData(toolName, filters) {
    var params = {...filters, toolName: toolName};
    return <any>this.apiClient.getWithParams(HomeLandingConstants.GET_TOOLS_SOFT_PROF_DATA_URL, params);
  }

  getFunctionEmpCountData() {
    return <any>this.apiClient.get(HomeLandingConstants.GET_FUNCTION_EMP_COUNT_DATA_URL);
  }

  getGeographyEmpCountData() {
    return <any>this.apiClient.get(HomeLandingConstants.GET_GEOGRAPHY_EMP_COUNT_DATA_URL);
  }

  getFunctionRangeEmpCountData(competancyVal, skillName) {
    return <any>this.apiClient.get(HomeLandingConstants.GET_FUNCTION_RANGE_EMP_COUNT_URL + '?competency=' + competancyVal + '&skillName=' + skillName);
  }

  getGeographyRangeEmpCountData(competancyVal, skillName) {
    return <any>this.apiClient.get(HomeLandingConstants.GET_GEOGRAPHY_RANGE_EMP_COUNT_URL + '?competency=' + competancyVal + '&skillName=' + skillName);
  }


  getSkillChat() {
    return this.apiClient.get('/api/report/data/report1');
  }

  getMultiBarchartSkillData() {
    return this.apiClient.get('/api/report/data/report2');
  }

  roundOffValue(array) {
    let newArray = []
    array.forEach(element => {
      element.value = Math.round((element.value + Number.EPSILON) * 100) / 100;
      newArray.push(element);
    });
    return newArray;
  }
}

import { AddWindowComponent } from './components/add-window/add-window.component';
import { ManageWindowComponent } from './components/manage-window/manage-window.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ManageProfileComponent } from './components/manage-profile/manage-profile.component';
import { LoginGuard } from 'libs/ui-base/src/lib/utilities/login.guard';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LandingComponent } from './components/landing/landing.component';
import { Dashboard1Component } from './components/dashboard1/dashboard1.component';

const routes: Routes = [
  /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: 'home', pathMatch: 'full', component: HomeComponent },
  { path: 'login', pathMatch: 'full', component: LoginComponent },
  { path: 'user/resetpassword', pathMatch: 'full', component: LoginComponent },
  {
    path: 'landing', pathMatch: 'full', component: LandingComponent,
    // canActivate: [LoginGuard]
  },
  {
    path: 'manage-profile', pathMatch: 'full', component: ManageProfileComponent,
  },
  {
    path: 'dashboard', pathMatch: 'full', component: DashboardComponent,
  },
  {
    path: 'add-window', pathMatch: 'full', component: AddWindowComponent,
  },
  {
    path: 'manage-window', pathMatch: 'full', component: ManageWindowComponent,
  },
  {
    path: 'skill',
    loadChildren: () => import('./skill/skill-routing.module').then((m) => m.SkillRoutingModule),

  }

]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],

  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
@Component({
  selector: 'base-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  @Input() id?: string;
  @Input() isDisabled?= false;
  @Input() isReadonly?= true;
  @Input() initialDate?: Date;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() minExclusive: boolean;
  @Input() maxExclusive: boolean;
  @Output() selectedDate: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (this.minExclusive) {
      this.minDate.setDate(this.minDate.getDate() + 1);
    }
    if (this.maxExclusive) {
      this.maxDate.setDate(this.maxDate.getDate() - 1);
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    const result = {
      id: this.id,
      selectedDate: event.value
    }
    this.selectedDate.emit(result);
  }

}

import { BarChartModel } from './../../typedef/api-model-class';
import { Component, Input, OnInit } from '@angular/core';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';

@Component({
  selector: 'hrs-base-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  @Input() chartConfig: any;
  constructor() {
  }

  ngOnInit(): void {
  }

  onSelect(event) {
  }

}

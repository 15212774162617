import { AddSkillRequest } from './../../typedef/api-model';
import { UtilityService } from 'libs/ui-base/src/lib/services/utility.service';
import { Observable } from 'rxjs';
import { SkillConstants } from '../constants/skill.constant';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiClient } from 'libs/ui-base/src/lib/services/api-client';
import { MatDialog } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class SkillService {
  public addSkillReqObjFields = ['skillCd', 'skillName', 'skillRemarks', 'skillDomain', 'parentId', 'parentName',
    'profiencyType', 'profiencyValue', 'competency', 'supervisorRating', 'supervisorRemark', 'weightage', 'noOfYears', 'addedBy'];

  constructor(private http: HttpClient,
    @Inject('Environment') private parentEnvironment: object,
    private apiClient: ApiClient,
    private dialog: MatDialog,
    private utilityService: UtilityService) { }

  invokeDialog(component: any, width: string, data: any, height?: string,) {
    const dialogRef = this.dialog.open(component, {
      width: width,
      height: height ? height : '',
      data: data
    });
    return dialogRef;
  }

  getSkillDetails(skillCd): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_SKILL_DETAILS + skillCd);
  }

  getSkillRatings(): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_PROFICIENCY_RATING);
  }

  getSkillDomains(): Observable<any> {
    return this.apiClient.get(SkillConstants.SKILL_DOMAIN);
  }

  getUserSkills(userId): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_USER_SKILLS + '/' + userId);
  }

  getFunctionSkills(userId): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_FUNCTIONAL_SKILLS + '/' + userId);
  }

  getGeographicalSkills(userId): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_GEOGRAPHICAL_SKILLS + '/' + userId);
  }

  getLanguageSkills(userId): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_LANGUAGES_SKILLS + '/' + userId);
  }

  getToolsAndSoftSkills(userId): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_TOOLS_AND_SOFT_SKILLS + '/' + userId);
  }

  getSkillList(user): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_SKILL_LIST + user);
  }

  addSkills(data) {
    let payload = { request: {} };
    payload = data;
    return <any>this.apiClient.post(SkillConstants.ADD_SKILLS, data);
  }

  reviewSkills(data) {
    let payload = { request: {} };
    payload = data;
    return <any>this.apiClient.post(SkillConstants.REVIEW_SKILLS, data);
  }

  getToolsAndSoftwareRatings(): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_PROFICIENCY_SCALE);
  }

  getFunctionalExp(): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_PROFICIENCY_EXP);
  }

  getGeographicalExp(): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_PROFICIENCY_EXP);
  }

  getLanguageProficiency(): Observable<any> {
    return this.apiClient.get(SkillConstants.GET_PROFICIENCY_LANGUAGE);
  }

  updateStatus(data) {
    let payload = { request: {} };
    payload = data;
    return <any>this.apiClient.post(SkillConstants.UPDATE_STATUS, data);
  }

  downloadSkillDoc(docId) {
    this.utilityService.fileDownloader(SkillConstants.DOWNLOAD_SKILL_DOC + docId);
  }

  deleteSkillDoc(userId, docId) {
    return <any>this.apiClient.get(SkillConstants.DELETE_SKILL_DOC + userId + '/' + docId);
  }

  deleteSkill(payload) {
    return <any>this.apiClient.post(SkillConstants.DELETE_SKILL, payload);
  }

  async fetchAndAddSkills(newSkills, accordianData) {
    if (newSkills && newSkills.length > 0) {
      if (!accordianData?.userSkills) {
        accordianData.userSkills = { skills: [] }
      }
      let getSkillCalls = newSkills.map(skill => {
        return this.getSkillDetails(skill).toPromise();
      })
      let newSkillData = await Promise.all(getSkillCalls);
      newSkillData.forEach((skillObj: AddSkillRequest) => {
        skillObj['status'] = '1000';
        skillObj['statusName'] = 'Pending Review';
        accordianData?.userSkills.skills.push(skillObj);
      });
      return accordianData;
    }
  }

  getPreSelectedSkills(accordian?) {
    const preSelectedSkills = []
    if (accordian?.userSkills) {
      accordian.userSkills.skills.forEach(element => {
        preSelectedSkills.push(element.skillCd);
      });
    }
    return preSelectedSkills;
  }
}

import { SkillAndProfiencyModel } from './../typedef/api-model';
import { ApiClient } from './../../../../../libs/ui-base/src/lib/services/api-client';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SkillHierarchy } from '../typedef/api-model';

@Injectable({
  providedIn: 'root'
})
export class SkillHierarchyService {

  constructor(private apiClient: ApiClient) { }

  getSkillHierarchy(accordian): any {
    return <Observable<SkillAndProfiencyModel[]>>this.apiClient.get('/api/hrskills/skills/hierarchy/' + accordian);
  }
}

import { Profile } from '../../typedef/api-model';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { ManageProfileService } from './../../services/manage-profile.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $;

@Component({
  selector: 'hrs-ui-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss']
})
export class ManageProfileComponent implements OnInit {
  public manageProfileForm = new Profile();
  qualificationList = [];
  constructor(private fb: FormBuilder, private manageProfileService: ManageProfileService, private authService: AuthService) { }

  ngOnInit(): void {
    this.populateFields();
  }

  populateFields() {
    this.authService.getLoggedInUser().subscribe(loggedInUser => {
      this.manageProfileService.getMyProfile().subscribe(profileData => {
        this.manageProfileForm = profileData;
        this.manageProfileForm.custom.doj = this.manageProfileForm.custom.doj ?
          new Date(this.manageProfileForm.custom.doj) : undefined;
      })
    })
  }

  collapseAll() {
    $('.card-header').addClass('collapsed');
    $('.card-body').removeClass('show');
  }
  expandAll() {
    $('.card-header').removeClass('collapsed');
    $('.card-body').addClass('show');
  }
}

import { HomeLandingConstants } from './../constants/home-landing.constant';
import { MatDialog } from '@angular/material/dialog';
import { ApiClient } from 'libs/ui-base/src/lib/services/api-client';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManageWindowService {

  constructor(private http: HttpClient,
    @Inject('Environment') private parentEnvironment: object,
    private apiClient: ApiClient, private dialog: MatDialog) { }

  invokeDialog(component: any, width: string, data?: any, height?: string,) {
    const dialogRef = this.dialog.open(component, {
      width: width,
      height: height ? height : '',
      data: data
    });
    return dialogRef;
  }

  getManageWindowGridData() {
    return <any>this.apiClient.get(HomeLandingConstants.GET_MANAGE_WINDOW_GRID);
  }

  getManageWindowTypes() {
    return <any>this.apiClient.get(HomeLandingConstants.GET_MANAGE_WINDOW_TYPES);
  }

  addOrEditWindow(payload) {
    return <any>this.apiClient.post(HomeLandingConstants.ADD_WINDOW, payload);
  }

  searchUsers(payload) {
    return <any>this.apiClient.post(HomeLandingConstants.USER_SEARCH, payload);
  }

  deleteWindow(id) {
    return this.apiClient.get(HomeLandingConstants.DELETE_WINDOW + '/' + id);
  }

}

import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'base-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

 
  showLoader = true;
  isTransperent = true;
  constructor(private spinnerService: SpinnerService) {
    this.spinnerService.isLoading.subscribe(val => {
      this.showLoader = val;
      // if (this.showLoader)
      //   this.startTimeOut();
    });

    this.spinnerService.isTransperent.subscribe(val => {
      this.isTransperent = val;
    });
  }

  ngOnInit(): void {
  }

  closeLoader() {
    this.spinnerService.isLoading.next(false);
  }

  startTimeOut() {
    setTimeout(() => {
      this.showLoader = false;
      // this.showSpinnerMessage();
    }, 5000);
  }

}

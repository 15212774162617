import { ApiClient } from './api-client';
import { messageStore } from './../utilities/messageStore';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private apiClient: ApiClient) { }

  getMessage(moduleKey, dataKey, paramsDispay?: any) {
    let value = messageStore[moduleKey][dataKey]
    if (value) {
      if (value.includes('{placeholder}')) {
        if (paramsDispay) {
          paramsDispay.forEach(element => {
            value = value.replace('{placeholder}', element)
          });
        }
        return value.replace(new RegExp('{placeholder}', 'g'), '')
      }
      return value;
    }
    return 'Key not found in message Store';
  }

  public fileDownloader(url) {
    this.apiClient.getMethodResponseParameter(url, { responseType: 'arraybuffer', observe: 'response' }).subscribe((data: any) => {
      let filename = "";
      let disposition = data.headers.get('Content-Disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }

      let blob = new Blob([data.body]);
      let downloadURLFile = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = downloadURLFile;
      link.download = filename;
      link.click();
    });
  }

  getValidationMessageArray(error) {
    const msgArray: string[] = [];
    let field;
    let message;
    let uiMessage;
    const valid = error.error.validationErrors;

    for (const i of valid) {
      field = i.field;
      if (field)
        field = field.replace(/([a-z])([A-Z])/g, '$1 $2');
      message = i.message;
      if (field == !'null') {
        uiMessage = field + ' : ' + message;
      }
      else {
        uiMessage = message;
      }
      msgArray.push(uiMessage);
    }
    return msgArray;
  }

  replaceStringwithValue(template, actionParams) {
    Object.keys(actionParams).forEach(param => {
      template = template.replaceAll('{' + param + '}', actionParams[param]);
    });
    return template;
  }

  filterDate(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split(' ')[0].split('-');
    const d = new Date(dateParts);
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(d.getMonth()),
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  }
}


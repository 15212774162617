import { NgForm, FormGroup } from '@angular/forms';
import { UserSkills, AddSkillRequest } from './../../../../typedef/api-model';
import { SkillConstants } from './../../../constants/skill.constant';
import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SkillTreeComponent } from './../../../../components/skill-tree/skill-tree.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { SkillService } from './../../../services/skill.service';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import _ from 'lodash';
import { AppService } from 'apps/hr-skills/src/app/app.component';
import { SkillDetailsComponent } from '../../skill-details/skill-details.component';
import { SkillHierarchyService } from 'apps/hr-skills/src/app/services/skill-hierarchy.service';


@Component({
  selector: 'hrs-ui-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss'],
})
export class SkillComponent implements OnInit {
  @ViewChild('skillForm') public skillForm: NgForm;
  skillData: UserSkills = new UserSkills();
  addSkillObj: AddSkillRequest = new AddSkillRequest();
  @Output() getSkillDataChange = new EventEmitter<UserSkills>();
  @ViewChild('saveSkillBtn', { read: ElementRef })
  saveSkillBtn: ElementRef<HTMLInputElement>;
  skillRatings;
  skillDomains = [];
  userId;
  uploadDocObj;
  addDocObj = {};
  uploadedFiles = [];
  isSupervisorAction = false;
  isViewMode = false;
  isSkillSaved: boolean;
  isDomainClicked = false;
  locallyAddedSkills = [];
  preSelectedSkills = [];
  dropdownSettings = {};
  selfRemarkColName = SkillConstants.SKILL_REMARK;
  isHr: boolean = false;
  arrDropDownNumbers = [];
  skillHierarchy:any;

  SkillConstants = SkillConstants;

  constructor(
    private skillService: SkillService,
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private skillHierarchyService: SkillHierarchyService
  ) {}

  ngOnInit(): void {
    this.initializeDropDownSetting();
    this.arrDropDownNumbers = Array(30)
      .fill(0)
      .map((x, i) => i + 1);
    console.log(this.arrDropDownNumbers);
    this.activatedRoute.queryParams.subscribe((queryparams) => {
      if (queryparams?.userId) {
        this.isHr = queryparams?.isHr;
        this.userId = queryparams?.userId;
        this.isViewMode = queryparams?.actionType == 'view' ? true : false;
        this.isViewMode = this.isHr ? false : this.isViewMode;
        this.isSupervisorAction = this.isHr ? false : true;
        this.selfRemarkColName = SkillConstants.SELF_RATING_REMARK;
        this.getSkillListAndRatings();
      } else {
        this.authService.getLoggedInUser().subscribe((res) => {
          if (res && res.userId) {
            this.userId = res.userId;
            this.getSkillListAndRatings();
          }
        });
      }
    });

    this.skillHierarchyService.getSkillHierarchy('').subscribe(h=>
      this.skillHierarchy = h
    )

  }

  async getSkillListAndRatings() {
    let domainsArray = [];
    this.getSkillData();
    this.skillRatings = await this.skillService.getSkillRatings().toPromise();
    domainsArray = await this.skillService.getSkillDomains().toPromise();
    domainsArray?.forEach((domain) => {
      this.skillDomains.push(domain.domainName);
    });
  }

  //Add skills
  addSkills() {
    this.preSelectedSkills = this.skillService.getPreSelectedSkills(
      this.skillData
    );
    let dialogData = {
      accordian: '',
      selectedSkills: this.preSelectedSkills,
    };

    this.skillService
      .invokeDialog(
        SkillTreeComponent,
        SkillConstants.WIDTH_50EM,
        dialogData,
        SkillConstants.HEIGHT_90_PERCENT
      )
      .afterClosed()
      .subscribe(async (newSkills) => {
        if (newSkills) {
          this.isSkillSaved = false;
          this.locallyAddedSkills = newSkills;
          this.skillData = await (<any>(
            this.skillService.fetchAndAddSkills(newSkills, this.skillData)
          ));
        }
      });
  }

  getSkillData() {
    this.skillService.getUserSkills(this.userId).subscribe((skillData) => {
      this.locallyAddedSkills = [];
      if (skillData.userSkills) {
        this.skillData = skillData;
        this.getSkillDataChange.emit(this.skillData);
        if (this.skillData.userSkills) {
          if (
            !this.isSupervisorAction &&
            this.skillData.userSkills.status != '1000'
          ) {
            this.isViewMode = true;
          } else if (
            this.isSupervisorAction &&
            this.skillData.userSkills.status == '9000'
          ) {
            this.isViewMode = true;
          }
        }
      }
      if (!this.appService.getIsEditWindowAvailable()) {
        this.isViewMode = true;
      }
      this.isViewMode = this.isHr ? false : this.isViewMode;
    });
  }

  //Change ratings
  changeRating(index) {
    // console.log(e.target.value, ">>", index);
    this.skillData.userSkills.skills[index].profiencyType =
      SkillConstants.RATING;
    // if (actionFrom === 'user') {
    //   this.skillData.userSkills.skills[index].profiencyValue = e.target.value
    // } else {
    //   this.skillData.userSkills.skills[index].supervisorRating = e.target.value

    // }
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  saveSkills(isSubmitCall?) {
    if (this.skillForm.valid) {
      if (this.skillData?.userSkills?.skills.length) {
        this.addSkillObj.userType = this.getUserType();
        this.addSkillObj.userId = this.skillData.userSkills.userId;
        this.addSkillObj.skills = this.skillData.userSkills.skills.map((v) => ({
          ...v,
          competency: SkillConstants.SKILL_COMPETENCY,
        }));
        console.log('this.addSkillObj.skills', this.addSkillObj.skills);

        for (let i = 0; i < this.addSkillObj.skills.length; i++) {
          this.addSkillObj.skills[i] = _.pick(
            this.addSkillObj.skills[i],
            this.skillService.addSkillReqObjFields
          );
        }
        if (this.isSupervisorAction || this.isHr) {
          this.reviewSkillCall(this.addSkillObj);
        } else {
          this.addSkillCall(this.addSkillObj, isSubmitCall);
        }
      }
    } else {
      this.skillForm.control.markAllAsTouched();
    }
  }

  addSkillCall(data, isSubmitCall) {
    if (!isSubmitCall) {
      this.skillService.addSkills(data).subscribe((res) => {
        if (res.id) {
          this.dialog
            .open(PopupModalComponent, {
              width: SkillConstants.WIDTH_500PX,
              position: { top: SkillConstants.WIDTH_20PX },
              data: {
                message: ['', SkillConstants.SAVE_SKILL_MSG],
                button: [SkillConstants.OK],
              },
              disableClose: false,
            })
            .afterClosed()
            .subscribe((response) => {
              this.skillForm.form.markAsPristine();
              this.isSkillSaved = true;
              this.getSkillData();
            });
        }
      });
    } else {
      this.skillService.addSkills(data).subscribe((res) => {
        if (res.id) {
          this.skillForm.form.markAsPristine();
          this.isSkillSaved = true;
          this.getSkillData();
        }
      });
    }
  }

  reviewSkillCall(data) {
    this.skillService.reviewSkills(data).subscribe((res) => {
      if (res.version) {
        this.dialog
          .open(PopupModalComponent, {
            width: SkillConstants.WIDTH_500PX,
            position: { top: SkillConstants.WIDTH_20PX },
            data: {
              message: ['', SkillConstants.SKILL_REVIEW_SAVE_MSG],
              button: [SkillConstants.OK],
            },
            disableClose: false,
          })
          .afterClosed()
          .subscribe((response) => {
            this.skillForm.form.markAsPristine();
            this.isSkillSaved = true;
            this.getSkillData();
          });
      }
    });
  }

  deleteSkill(index) {
    const data = this.dialog.open(PopupModalComponent, {
      width: SkillConstants.WIDTH_350PX,
      data: {
        message: [SkillConstants.DELETE_SKILL_MSG],
        button: [SkillConstants.YES, SkillConstants.CANCEL],
      },
    });

    data.afterClosed().subscribe((res) => {
      if (res == SkillConstants.YES) {
        if (
          !this.locallyAddedSkills.includes(
            this.skillData.userSkills.skills[index]?.skillCd
          )
        ) {
          const payload = {
            skillCd: this.skillData.userSkills.skills[index]?.skillCd,
            userId: this.skillData.userSkills?.userId,
          };
          this.skillService.deleteSkill(payload).subscribe((res) => {
            if (res.result === 'success') {
            }
          });
        }
        this.skillData.userSkills.skills.splice(index, 1);
      }
    });
  }

  showSkillDetails(skillCd,type?) {
    //  this.router.navigate(['skill/skill-details'], {
    //  queryParams: { skillCd: skillCd },

    const dialogRef = this.dialog.open(SkillDetailsComponent, {
      width: '600px',
      height: '600px',
      data: { skillCd: skillCd, type:type },
    });
    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
    });
    return dialogRef;
  }

  onDomainClick() {
    this.isDomainClicked = true;
  }

  onDomainClose() {
    this.isDomainClicked = false;
  }

  initializeDropDownSetting() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'domainCd',
      textField: 'domainName',
      enableCheckAll: false,
      unSelectAllText: '',
      allowSearchFilter: false,
      maxHeight: 100,
      itemsShowLimit: 2,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Available',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
  }

  fetchDeleteButtonState(skill) {
    let currentUser;
    if (this.isSupervisorAction) {
      currentUser = SkillConstants.SUPERVISOR;
    } else if (this.isHr) {
      currentUser = SkillConstants.HUMAN_RESOURCES;
    } else {
      currentUser = SkillConstants.EMPLOYEE;
    }

    if (currentUser == SkillConstants.HUMAN_RESOURCES) {
      return true;
    }

    return !this.isViewMode && (!skill.addedBy || skill.addedBy == currentUser);
  }
  getUserType() {
    let userType;
    if (this.isHr && !this.isSupervisorAction) {
      userType = SkillConstants.HUMAN_RESOURCES;
    }
    if (this.isSupervisorAction && !this.isHr) {
      userType = SkillConstants.SUPERVISOR;
    }
    if (!this.isHr && !this.isSupervisorAction) {
      userType = 'employee';
    }
    return userType;
  }

getLevel1Name(skillCd){
  let skill = this.skillHierarchy.skillList.filter(h=>h.skillCd == skillCd)[0];
  if(skill.level == 3){
   return skill.level1Name + " - ";
  }
  return '';
  }
}

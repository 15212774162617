import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { empty, Observable, of, Subscription } from 'rxjs';
import { PageData } from '../../typedef/common-models';
import { GridOptions } from 'ag-grid-community';
import { GridService } from '../../services/grid.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { UtilityService } from '../../services/utility.service';
import { tap, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'base-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnDestroy, OnChanges {

  @Input() inputConfig: any;
  @Input() serverParams: any;
  @ViewChild('gridApi', { static: false }) gridApi: any;
  @Output() selectedRowData: EventEmitter<any>;
  @Output() customExporter: EventEmitter<any>;

  dataConfig: any;
  pageData: PageData;
  refreshSubscription: Subscription;
  dataArray = [];
  paginationPageSize = 1000;
  totalPages: number;
  totalRecords: number;
  page = 1;
  noRowsTemplate = '';
  rowSelection: true;
  currentURL: string;
  gridObservable: Observable<any>;
  currentPageNumber: number;
  errorObject: any;
  totalItems: number;
  URL: string;
  gridOptions = {} as GridOptions
  private frameworkComponents;
  public modules: any[] = [ClientSideRowModelModule];

  constructor(
    private gridService: GridService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public platform: Platform,
    private utilityService: UtilityService
  ) {
    this.currentURL = this.router.url;
    this.selectedRowData = new EventEmitter();
    this.customExporter = new EventEmitter();
  }

  ngOnInit() {
    const paramsConfig = this.activatedRoute.snapshot.data.config;
    if (this.inputConfig) {
      this.dataConfig = this.inputConfig;
    } else {
      this.dataConfig = paramsConfig;
    }
    if (this.dataConfig.gridOptions) {
      this.gridOptions = this.dataConfig.gridOptions;
    }

    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    //first Onchanges is called before ngOnInit
    this.loadData();
  }
    
  private loadData() {
    if (!this.dataConfig) {
      return;
    }
    this.URL = this.dataConfig.serverURL + '?pageNumber=' + this.page + '&pageSize=' + this.paginationPageSize;
    if (this.serverParams) {
      Object.keys(this.serverParams).forEach(param => {
        this.URL += "&" + param + "=" + this.serverParams[param];
      });
    }
    if (this.inputConfig) {
      if ('data' in this.inputConfig) {
        this.dataArray = this.inputConfig.data;
        this.gridObservable = of('Data Resolved');
      } else {
        this.getServerData(this.URL);
      }
    } else {
      this.getServerData(this.URL);
    }
    this.refreshSubscription = this.gridService
      .getRefreshGridSubject().subscribe((res: any) => {
        if (res.status) {
          this.getServerData(this.URL);
        }
      });
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
  }

  getServerData(URL: string) {
    const mappingObject = this.dataConfig.serverMapping;
    this.dataArray = [];
    this.gridObservable = this.gridService.getGridData(URL, this.dataConfig?.postRequestBody).pipe(
      tap((res: any) => {
        this.totalItems = res.totalPages * this.paginationPageSize;
        this.pageData = { currentPage: this.page, maxPages: res.totalPages };
        this.totalRecords = res.totalElements;
        res.content.forEach(element => {
          const tempObject = {};
          Object.keys(mappingObject).forEach(key => {
            let data = _.get(element, mappingObject[key].name, 'NA');
            if ('mapping' in mappingObject[key]) {
              if (mappingObject[key]['mapping'] == 'date') {
                tempObject[key] = moment(data).format('DD-MMM-YYYY  hh:mm');
              } else if (
                mappingObject[key]['mapping'] == 'dateWithMonthNameTimeFormat'
              ) {
                tempObject[key] = moment(data).isValid()
                  ? moment(data).format('DD-MMM-YYYY  HH:mm')
                  : 'NA';
              } else if (
                mappingObject[key]['mapping'] == 'dateWithMonthNameFormat'
              ) {
                tempObject[key] = moment(data).isValid()
                  ? moment(data).format('DD-MMM-YYYY')
                  : 'NA';
              } else if (mappingObject[key]['mapping'] == 'tenure') {
                let symbol = _.get(element, mappingObject['limits'].name, 'NA');
                tempObject[key] = data + symbol.tenureUoMSymbol;
              } else if (mappingObject[key]['mapping'] == 'amount') {
                let symbol = _.get(element, mappingObject['limits'].name, 'NA');
                tempObject[key] = data + symbol.amountUoMSymbol;
              } else if (mappingObject[key]['mapping'] == 'boolean') {
                data === true
                  ? (tempObject[key] = 'Yes')
                  : (tempObject[key] = 'No');
              } else if (mappingObject[key]['mapping'] == 'formatMobileNumber') {
                tempObject[key] = !!data ? '+91 ' + data : 'NA';
              } else if (mappingObject[key]['mapping'] == 'isLive') {
                tempObject[key] = (data === 'NA') ? true : false;
              } else {
                tempObject[key] = mappingObject[key]['mapping'][data]
                  ? mappingObject[key]['mapping'][data]
                  : mappingObject[key]['mapping'][999999];
              }
            } else {
              tempObject[key] = data;
            }
            typeof tempObject[key] === 'boolean' ? tempObject[key] = tempObject[key] :
              // tempObject[key] = tempObject[key] ? tempObject[key] : 'NA';
              tempObject[key] = ((tempObject[key] !== undefined) && (tempObject[key] !== null)
                && (tempObject[key] !== "")) ? tempObject[key] : 'NA';
          });
          this.dataArray.push(tempObject);
          this.gridService.setGridDataSource(res);
        });
      }),
      catchError(err => {
        this.errorObject = err;
        return empty();
      })
    );
  }

  pagination(event: any) {
    this.page = event;
    let url = this.dataConfig.serverURL + '?pageNumber=' + this.page + '&pageSize=' + this.paginationPageSize;
    this.getServerData(url);
  }

  navigateToPage(url) {
    this.router.navigate([url]);
  }

  checkSameRoute(url) {
    if (this.currentURL.includes(url)) return true;
    return false;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    // this.gridApi.sizeColumnsToFit();
  }

  clearAgeFilter() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onRowSelection(event) {
    let selRow = this.gridApi.getSelectedRows();
    event.selRow = selRow;
    this.gridService.onSelection(event);
    // if(inputConfig.)
  }

  exportData() {
    const fileName = this.dataConfig.csvFileName ? (this.dataConfig.csvFileName + new Date().getTime()) : ('Dashboard' + new Date().getTime());
    if (this.platform.IOS || this.platform.SAFARI) {
      let gridData = this.gridApi.getDataAsCsv();
      this.gridService.sendGridData(fileName, gridData)
        .subscribe(data => {
          let blob = new Blob([data.body]);
          let downloadURLFile = window.URL.createObjectURL(blob);
          let link = document.createElement('a');
          link.href = downloadURLFile;
          link.download = fileName + '.csv';
          link.click();
        },
          error => console.log('error in sending grid data is -> ', error)
        );
    } else {
      if (this.dataConfig.exportFunction) {
        this.customExporter.emit({});
      } else {
        this.gridApi.exportDataAsCsv({ fileName: fileName });
      }
    }
  }

  cellClickedEvent(cell) {
    this.selectedRowData.emit(cell);
  }

  refreshCell() {
    this.ngOnInit();
    //this.gridOptions.api.setRowData(this.gridOptions.rowData)
  }
}


import { Observable } from 'rxjs';
import { HomeLandingConstants } from '../constants/home-landing.constant';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiClient } from 'libs/ui-base/src/lib/services/api-client';

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(private http: HttpClient,
    @Inject('Environment') private parentEnvironment: object,
    private apiClient: ApiClient) { }

  getLandingTiles(): Observable<any> {
    console.log("landing" + this.parentEnvironment)
    return this.apiClient.get(HomeLandingConstants.LANDING_TILES_URL);
  }
}

import { Injectable } from '@angular/core';
import * as Bowser from 'bowser';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { UiEnvSetupService } from './ui-env-setup.service';

@Injectable({
  providedIn: 'root'
})
export class StarterService {

  stopApp = new BehaviorSubject<boolean>(false);
  stopAppMsg = new BehaviorSubject<any>("");

  constructor(private auth: AuthService, private uiEnv: UiEnvSetupService) { }

  init() {

    const browser = Bowser.getParser(window.navigator.userAgent);

    var screenWidth = window.screen.availWidth;
   
    let browserName: any = browser?.getBrowser()?.name?.toLowerCase();
    if (JSON.stringify(browserName).includes('internet explorer')) {
      this.stopApp.next(true);
      this.stopAppMsg.next(browserName + " is not a supported browser for Sufin platform. Please use Chrome, Firefox or Microsoft edge for best results");
    } else if (screenWidth < 300) {
      this.stopApp.next(true);
      this.stopAppMsg.next(screenWidth + "px is not supported resoultion for sufin platform ,width of device should be grater than 300px");
    } else {
      var onloadLocation = window.location.search;
      //this.uiEnv.loadEnv().subscribe(res=>this.auth.init(res, onloadLocation));
      //this.auth.init();
    }
  }
}

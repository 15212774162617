import { ErrorHttpService } from './../../services/error-http.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'base-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  errMsg: string[];

  constructor(private errSer: ErrorHttpService, private router: Router) { }

  ngOnInit(): void {
    this.errSer.errMsgPage.subscribe(errData => {
      this.errMsg = errData.msg
    });
  }

}

// Function validated the file uploaded against permissible Size & Extensions.
// Returns result object with attributes hasSizeExceeded & hasInvalidFormat specifing validation result.
// 1. uploadEvent - Change event tirggered when user uploads file.
// 2. allowedSize - Maxmimum permissible size range for file upload.
// 3. allowedExtensions - An array of strings representing allowed extensions for file upload.
export function FileUploadValidator(uploadEvent, allowedSize, allowedExtensions) {
  const file = uploadEvent.target.files[0];
  const fileNameArr = file.name.split('.');
  let result = { hasSizeExceeded : false, hasInvalidFormat : false };
  
  if(file.size > allowedSize) result.hasSizeExceeded = true;
  if(fileNameArr.length <= 1) result.hasInvalidFormat = true;    
  else if (fileNameArr.length > 1) {
    if(allowedExtensions && !allowedExtensions?.includes(fileNameArr[fileNameArr.length - 1].toLowerCase()))
    result.hasInvalidFormat = true;
  }

  return result;
}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHttpService {

  public errMsgPage = new BehaviorSubject<any>({});
  public errMsgSec = new BehaviorSubject<any>({});
  public clearPopUperrorMsgFlag = new BehaviorSubject<boolean>(false);

  constructor() { }

  handleErrorPage(serverErr, otherParameters, uiMsg) {
      this.errMsgPage.next({ error: true, msg: uiMsg });
  }

  handleErrorDiv(serverErr, otherParameters, uiMsg) {
      this.errMsgSec.next({ error: true, msg: uiMsg });
  }
}

import { RouterHistoryService } from 'libs/ui-base/src/lib/services/router-history.service';
import { Component, Injectable } from '@angular/core';
import { ApiClient } from 'libs/ui-base/src/lib/services/api-client';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { HomeLandingConstants } from './constants/home-landing.constant';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  isEditWindowAvailable = new BehaviorSubject<boolean>(false);
  constructor(private apiClient: ApiClient) {}

  isEditWindowAvailableForUser() {
    return this.apiClient.get(HomeLandingConstants.IS_EDIT_WINDOW_AVAILABLE);
  }

  setIsEditWindowAvailable(value) {
    this.isEditWindowAvailable.next(value);
  }

  getIsEditWindowAvailable() {
    return this.isEditWindowAvailable.getValue();
  }
}

@Component({
  selector: 'hrs-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  
  title = 'hr-skills';
  serverDown = false;

  constructor( private routerHistory: RouterHistoryService, 
    private appService: AppService, 
    private authService: AuthService, 
    private msalService: MsalService, 
    private router: Router) { }

  ngOnInit() {
    this.authService.init();
    this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          this.authService.setAuth(result);
        }
      },
      error: (error) => console.log(error)
    });
  
    this.authService.isServerDown().subscribe((isServerDown) => {
      this.serverDown = isServerDown;
    });
    this.routerHistory.loadRouting();
    this.appService.isEditWindowAvailableForUser().subscribe((res) => {
      this.appService.setIsEditWindowAvailable(res);
    }, err => {
      if(err.status == 0) {
        this.authService.setServerDown(true);
      }
    });

    this.authService.getLoggedInUser().subscribe({next: user=> {
      if (user != null) {
        this.router.navigate(["landing"]);
      } else {
        this.router.navigate(["home"]);
      }
    }});
  }
}
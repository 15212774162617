import { GridModel } from 'libs/ui-base/src/lib/typedef/common-models';
import { Component, OnInit } from '@angular/core';
import { fetchSkillGridDataForHR } from '../../constants/skill-grid.constants';
import { SkillService } from '../../services/skill.service';
import { SkillTreeComponent } from '../../../components/skill-tree/skill-tree.component';
import { SkillConstants } from '../../constants/skill.constant';
import { SkillHierarchyService } from '../../../services/skill-hierarchy.service';
import { UtilityService } from 'libs/ui-base/src/lib/services/utility.service';

@Component({
  selector: 'hrs-ui-hr-skill-grid',
  templateUrl: './hr-skill-grid.component.html',
  styleUrls: ['./hr-skill-grid.component.scss']
})
export class HrSkillGridComponent implements OnInit {
  agGridConst: GridModel;
  filterValues: any = {skills:[], cluster:"", cadre:"", geo:"", tool:"", domain:""};
  filters = {
    clusters: ["AHMEDABAD", "AHMEDABAD CTP-3R", "AHMEDABAD RREC", "BANGLADESH", "CHENNAI RL", 
      "CHENNAI RREC", "DELHI CTP-14", "DELHI DIAL", "DELHI RL", "DELHI RL DFCC", "DELHI RREC", 
      "JABALPUR RREC", "JAIPUR DFCC", "JAIPUR EMP4", "KENYA", "KOLKATA RL", "KOLKATA RREC", 
      "MAURITIUS", "MUMBAI", "MUMBAI RL", "MUMBAI RREC", "OMAN", "SAUDI", "TFL HSR", "UAE"],
    cadres: ["Classified - C", "EVP", "M4-B", "Cov Grade M4-B", "M4-A", "Unclassified - R", 
      "M3-C", "M3-B", "M3-A", "M2-C", "M2-B", "M2-A", "M1-C", "M1-B", "M1-A", "O2", "CA1", 
      "O1", "S2", "CMA", "S1", "TC11", "TC10", "TC9", "TC8", "TC7", "TC6", "TC5", "Driver III", 
      "Driver IV", "TC4", "TC3", "TC2", "FTC", "Retainer", "GCT", "GST", "DET", "GET", 
      "ET - Graduate", "PGET", "PGET-NICMAR", "PGT", "BIS Trainee", "Expat"],
    tools:[],
    geos: [],
    domains: []
  }

  serverParams: any= {skills:[], cluster:"", cadre:""};

  constructor(private skillService : SkillService,
    private skillHierarchyService: SkillHierarchyService,
    private util: UtilityService) { }

  ngOnInit(): void {
    this.setAggridConst();
    this.skillHierarchyService.getSkillHierarchy(SkillConstants.TOOLSANDSOFTWARE).subscribe(data =>
      this.filters.tools = data.skillList);
    this.skillHierarchyService.getSkillHierarchy(SkillConstants.GEOGRAPHICAL).subscribe(data =>
      this.filters.geos = data.skillList);
    this.skillService.getSkillDomains().subscribe(data=>
      this.filters.domains = data);
  }

  setAggridConst() {
    let gridConfig:any  = fetchSkillGridDataForHR();
    gridConfig.exportFunction = function() {this.exportSkillData()}; 
    this.agGridConst = gridConfig
  }

  selectSkillFilter() {
    let dialogData = {
      accordian: '',
      selectedSkills: []
    };

    this.skillService.invokeDialog(SkillTreeComponent, SkillConstants.WIDTH_50EM, dialogData,
      SkillConstants.HEIGHT_90_PERCENT)
      .afterClosed().subscribe(selectedSkills => {
        var skillFilter = [];
        this.skillHierarchyService.getSkillHierarchy('').subscribe((data:any)=> {
          data.skillList.forEach(row=> {
            if (selectedSkills.indexOf(row.skillCd) >= 0) 
              skillFilter.push(row); 
          });
          this.filterValues.skills = skillFilter;
        });
      });
  }

  loadDataWithFilters() {
    this.serverParams = {skills: this.filterValues.skills.map(c=>c.skillName),
        cluster: this.filterValues.cluster,
        cadre: this.filterValues.cadre,
        tool: this.filterValues.tool,
        geo: this.filterValues.geo,
        domain: this.filterValues.domain
      };
  }

  exportSkillData() {
    let url = SkillConstants.EXPORT_HR_SKILL_GRID;
    if (this.serverParams) {
      url += `?skills=${this.serverParams.skills}&cluster=${this.serverParams.cluster}&cadre=${this.serverParams.cadre}`;
    }
    this.util.fileDownloader(url);
  }

  removeMe(skill) {
    const index = this.filterValues.skills.indexOf(skill);
    if (index > -1) {
      this.filterValues.skills.splice(index, 1);
    }
  }
}

import { UtilityService } from './../../services/utility.service';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'base-confirmation-pop-up',
  templateUrl: './confirmation-pop-up.component.html',
  styleUrls: ['./confirmation-pop-up.component.scss']
})
export class ConfirmationPopUpComponent implements OnInit {

  constructor(@Optional() public dialogRef: MatDialogRef<ConfirmationPopUpComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private utility: UtilityService) { }

  requestId;
  requestText;
  isReqForSupport: boolean = true;
  ngOnInit(): void {
    this.requestId = this.data.requestId;
    this.requestText = this.data.requestText;
    console.log("this.data.isReqForSupport", this.data.isReqForSupport)
    if (this.data.isReqForSupport !== undefined) {
      this.isReqForSupport = this.data.isReqForSupport;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}

export const messageStore = {

  HTTPERROR: {
    404: 'Resource not found',
    500: 'Oops! something went wrong. Please contact your system administrator',
    401: 'You are not authenticated to access this page',
    403: 'You are not authorized to access this page, please contact your system admin.',
    ANY: 'Something went wrong, Please contact System Administrator',
    400: 'Http error: Bad Request',
    SESSION: 'Your session has timed out due to inactivity. Please login to continue.',
    DEACTIVATE: 'Your account has been deactivated. Please contact sufin support.'
  },

  VALIDATION: {
    INVALID_EMAIL:
      'Invalid Email {placeholder}, please enter valid email address {placeholder}',
    REQUIRED_EMAIL: 'Email ID  is required',
    REQUIRED_USERNAME: 'Username is mandatory field',
    INVALID_FILE_FORMAT: 'Uploaded file {placeholder} format is not allowed.',
  },
  GENERIC: {
    FILE_UPLD_CONFIRMATION: 'Do you want to upload {placeholder} ?',
  }
}
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiClient } from './api-client';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  refreshGridSubject: Subject<any>;
  gridDataSource: Subject<any> = new Subject();

  public cellEvent = new BehaviorSubject<any>({});

  constructor(private httpService: ApiClient, @Optional() @Inject('Environment') private parentEnvironment, private httpClient: HttpClient) {
    this.refreshGridSubject = new Subject();
  }

  getGridData(url, data?: any) {
    if (data)
      return this.httpService.post(url, data, { serverError: 'custom' });
    else {
      return this.httpService.get(url, { serverError: 'custom' });

    }
  }

  selfAssign(url, body) {
    const otherParams = {
      serverError: 'snack'
    };
    return this.httpService.post(url, body, otherParams);
  }

  onSelection(eventData) {
    this.cellEvent.next({ eventData });
  }

  getRefreshGridSubject() {
    return this.refreshGridSubject.asObservable();
  }

  setRefreshGridSubject(status: boolean) {
    this.refreshGridSubject.next({ status: status });
  }

  setGridDataSource(data: any) {
    this.gridDataSource.next({ data: data });
  }

  getGridDataSource() {
    return this.gridDataSource.asObservable();
  }
  sendGridData(fileName, body) {
    return this.httpClient.post(this.parentEnvironment.sendGridData + '/' + fileName + '.csv', body, { responseType: 'arraybuffer', observe: 'response' });
  }
}

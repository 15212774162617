import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from './api-client';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private apiClient: ApiClient, @Optional() @Inject('Environment') private parentEnvironment) { }

  fetchNotifications(pageNo, pageSize) {
    return this.apiClient.get(this.parentEnvironment['fetchAllNotifications'] + `?pageNumber=${pageNo}&pageSize=${pageSize}`, { customExemption: ['404', '401', '500'] });
  }

  markNotificationAsRead(id) {
    return this.apiClient.post(
      this.parentEnvironment.markNotificationAsRead + `?id=${id}`,
      { id: id }
    );
  }

  getAllNotifications(pageNo, pageSize) {
    return <Observable<Notification>>(
      this.apiClient.get(
        this.parentEnvironment.allNotifications +
        `?pageNumber=${pageNo}&pageSize=${pageSize}`
      )
    );
  }
  getNewNotifications(pageNo, pageSize) {
    return <Observable<Notification>>(
      this.apiClient.get(
        this.parentEnvironment.newNotifications +
        `?pageNumber=${pageNo}&pageSize=${pageSize}`, { customExemption: ['404', '401', '500'] }
      )
    );
  }
}

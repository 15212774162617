import { NgForm } from '@angular/forms';
import { ConfirmationPopUpComponent } from 'libs/ui-base/src/lib/components/confirmation-pop-up/confirmation-pop-up.component';
import { SkillConstants } from './../../../constants/skill.constant';
import { UploadDocComponent } from './../../upload-doc/upload-doc.component';
import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { ViewUploadedDocsComponent } from '../../view-uploaded-docs/view-uploaded-docs.component';
import { MatDialog } from '@angular/material/dialog';
import { SkillTreeComponent } from './../../../../components/skill-tree/skill-tree.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { SkillService } from './../../../services/skill.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserSkills, AddSkillRequest } from './../../../../typedef/api-model';
import _ from 'lodash';
import { AppService } from 'apps/hr-skills/src/app/app.component';

@Component({
  selector: 'hrs-ui-geographical-exp',
  templateUrl: './geographical-exp.component.html',
  styleUrls: ['./geographical-exp.component.scss'],
})
export class GeographicalExpComponent implements OnInit {
  @ViewChild('geographicalExpForm') public geographicalExpForm: NgForm;
  geographicalExpData: UserSkills = new UserSkills();
  addSkillObj: AddSkillRequest = new AddSkillRequest();
  geograhicalExp;
  userId;
  uploadDocObj;
  addDocObj = {};
  uploadedFiles = [];
  isSupervisorAction = false;
  isViewMode = false;
  isSkillSaved: boolean;
  locallyAddedSkills = [];
  preSelectedSkills = [];
  selectedItems = [];
  isHr: boolean = false;
  SkillConstants = SkillConstants;
  constructor(
    private skillService: SkillService,
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((queryparams) => {
      if (queryparams?.userId) {
        this.userId = queryparams?.userId;
        this.isHr = queryparams?.isHr;
        this.isViewMode = queryparams?.actionType == 'view' ? true : false;
        this.isViewMode = this.isHr ? false : this.isViewMode;
        this.isSupervisorAction = this.isHr ? false : true;
        this.getSkillListAndRatings();
      } else {
        this.authService.getLoggedInUser().subscribe((res) => {
          if (res && res.userId) {
            this.userId = res.userId;
            this.getSkillListAndRatings();
          }
        });
      }
    });
  }

  async getSkillListAndRatings() {
    this.getGeographicalExpData();
    this.geograhicalExp = await this.skillService
      .getGeographicalExp()
      .toPromise();
  }
  getGeographicalExpData() {
    this.skillService
      .getGeographicalSkills(this.userId)
      .subscribe((geographicalExpData) => {
        this.geographicalExpData = geographicalExpData;
        if (this.geographicalExpData.userSkills) {
          if (
            !this.isSupervisorAction &&
            this.geographicalExpData.userSkills.status != '1000'
          ) {
            this.isViewMode = true;
          } else if (
            this.isSupervisorAction &&
            this.geographicalExpData.userSkills.status == '9000'
          ) {
            this.isViewMode = true;
          }
        }
        if (!this.appService.getIsEditWindowAvailable()) {
          this.isViewMode = true;
        }
        this.isViewMode = this.isHr ? false : this.isViewMode;
      });
  }

  changeGeographicalExp(index) {
    this.geographicalExpData.userSkills.skills[index].profiencyType =
      SkillConstants.EXPERIENCE;
    // if (actionFrom === 'user') {
    //   this.geographicalExpData.userSkills.skills[index].profiencyValue = e.target.value
    // } else {
    //   this.geographicalExpData.userSkills.skills[index].supervisorRating = e.target.value
    // }
  }
  addGeographicalExp() {
    this.preSelectedSkills = this.skillService.getPreSelectedSkills(
      this.geographicalExpData
    );
    let dialogData = {
      accordian: SkillConstants.GEOGRAPHICAL,
      selectedSkills: this.preSelectedSkills,
    };

    this.skillService
      .invokeDialog(SkillTreeComponent, SkillConstants.WIDTH_50EM, dialogData)
      .afterClosed()
      .subscribe((newSkills) => {
        this.skillService.fetchAndAddSkills(
          newSkills,
          this.geographicalExpData
        );
      });
  }

  saveGeographicalExp(isSubmitCall?) {
    console.log('DIRTY ', this.geographicalExpForm.dirty);
    if (this.geographicalExpForm.valid) {
      if (this.geographicalExpData?.userSkills?.skills.length) {
        this.geographicalExpData.userSkills.userId = this.userId;
        this.addSkillObj.userType = this.getUserType();
        this.addSkillObj.userId = this.geographicalExpData.userSkills.userId;
        this.addSkillObj.skills = this.geographicalExpData.userSkills.skills.map(
          (v) => ({ ...v, competency: SkillConstants.GEOGRAPHICAL_COMPETENCY })
        );
        for (let i = 0; i < this.addSkillObj.skills.length; i++) {
          this.addSkillObj.skills[i] = _.pick(
            this.addSkillObj.skills[i],
            this.skillService.addSkillReqObjFields
          );
        }
        if (this.isSupervisorAction || this.isHr) {
          this.reviewSkillCall(this.addSkillObj);
        } else {
          this.addSkillCall(this.addSkillObj, isSubmitCall);
        }
      }
    } else {
      this.geographicalExpForm.control.markAllAsTouched();
    }
  }

  addSkillCall(data, isSubmitCall) {
    if (!isSubmitCall) {
      this.skillService.addSkills(data).subscribe((res) => {
        if (res.id) {
          this.dialog
            .open(PopupModalComponent, {
              width: SkillConstants.WIDTH_500PX,
              position: { top: SkillConstants.WIDTH_20PX },
              data: {
                message: ['', SkillConstants.SAVE_SKILL_MSG],
                button: [SkillConstants.OK],
              },
              disableClose: false,
            })
            .afterClosed()
            .subscribe((response) => {
              this.geographicalExpForm.form.markAsPristine();
              this.isSkillSaved = true;
              this.getGeographicalExpData();
            });
        }
      });
    } else {
      this.skillService.addSkills(data).subscribe((res) => {
        if (res.id) {
          this.isSkillSaved = true;
          this.getGeographicalExpData();
        }
      });
    }
  }

  deleteSkill(index) {
    const data = this.dialog.open(PopupModalComponent, {
      width: SkillConstants.WIDTH_350PX,
      data: {
        message: [SkillConstants.DELETE_SKILL_MSG],
        button: [SkillConstants.YES, SkillConstants.CANCEL],
      },
    });

    data.afterClosed().subscribe((res) => {
      if (res == SkillConstants.YES) {
        if (
          !this.locallyAddedSkills.includes(
            this.geographicalExpData.userSkills.skills[index]?.skillCd
          )
        ) {
          const payload = {
            skillCd: this.geographicalExpData.userSkills.skills[index]?.skillCd,
            userId: this.geographicalExpData.userSkills?.userId,
          };
          this.skillService.deleteSkill(payload).subscribe((res) => {
            if (res.result === 'success') {
            }
          });
        }
        this.geographicalExpData.userSkills.skills.splice(index, 1);
      }
    });
  }
  reviewSkillCall(data) {
    this.skillService.reviewSkills(data).subscribe((res) => {
      if (res.version) {
        this.dialog
          .open(PopupModalComponent, {
            width: SkillConstants.WIDTH_500PX,
            position: { top: SkillConstants.WIDTH_20PX },
            data: {
              message: ['', SkillConstants.SKILL_REVIEW_SAVE_MSG],
              button: [SkillConstants.OK],
            },
            disableClose: false,
          })
          .afterClosed()
          .subscribe((response) => {
            this.geographicalExpForm.form.markAsPristine();
            this.isSkillSaved = true;
            this.getGeographicalExpData();
          });
      }
    });
  }
  fetchDeleteButtonState(geography) {
    let currentUser;
    if (this.isSupervisorAction) {
      currentUser = SkillConstants.SUPERVISOR;
    } else if (this.isHr) {
      currentUser = SkillConstants.HUMAN_RESOURCES;
    } else {
      currentUser = SkillConstants.EMPLOYEE;
    }

    if (currentUser == SkillConstants.HUMAN_RESOURCES) {
      return true;
    }
    return (
      !this.isViewMode &&
      (!geography.addedBy || geography.addedBy == currentUser)
    );
  }
  getUserType() {
    let userType;
    if (this.isHr && !this.isSupervisorAction) {
      userType = SkillConstants.HUMAN_RESOURCES;
    }
    if (this.isSupervisorAction && !this.isHr) {
      userType = SkillConstants.SUPERVISOR;
    }
    if (!this.isHr && !this.isSupervisorAction) {
      userType = 'employee';
    }
    return userType;
  }
}

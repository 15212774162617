import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { Router } from '@angular/router';
import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'hrs-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  authorized: boolean;
  user: any;

  constructor(private router: Router, private authService: AuthService, 
    private dialog: MatDialog, 
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) { }

  ngOnInit(): void {
    this.authService.getLoggedInUser().subscribe({next: user=> {
      this.user = user;
      this.authorized = user != null;
    }});
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  logout() {
    const data = this.dialog.open(PopupModalComponent, {
      width: '350px',
      data: {
        message: ['Are you sure you want to logout?\nAny unsaved data will be lost.'],
        button: ['OK', 'Cancel']
      }
    });

    data.afterClosed().subscribe(res => {
      //user click on confirm logout ...
      if (res == 'OK') {
        this.authService.clearJwtToken();
        this.msalService.logout();
      }
    });
  }

  goToManageProfile() {
    this.router.navigate(['manage-profile'])
  }

  getToLanding() {
    if (this.authorized) {
      this.router.navigate(['landing']);
    }
    else {
      this.router.navigate(['home']);
    }
  }
}



export class User {
  fullName: string;
  name: string;
  userId: string;
  organization: string;
}
import { ManageWindowActionRenderComponent } from './manage-window-action-render.component';
import { Router } from '@angular/router';
import { ManageWindowService } from './../../services/manage-window.service';
import { GridModel } from 'libs/ui-base/src/lib/typedef/common-models';
import { Component, OnInit, ViewChild } from '@angular/core';
import { getManageWindowDetails } from '../../constants/manage-window-grid.constants';

@Component({
  selector: 'hrs-ui-manage-window',
  templateUrl: './manage-window.component.html',
  styleUrls: ['./manage-window.component.scss']
})
export class ManageWindowComponent implements OnInit {

  agGridConst: GridModel;
  isAddWindowDisabled;

  constructor(public manageWindowService: ManageWindowService, public router: Router) { }

  ngOnInit(): void {
    this.setAggridConst();
  }

  setAggridConst() {
    this.agGridConst = getManageWindowDetails();
  }

  addWindow() {
    this.router.navigate(['./add-window'])
  }

}

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { UtilityService } from '../../services/utility.service';
import { Notification } from '../../typedef/common-models'

@Component({
  selector: 'base-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  pageNo = 1;
  pageSize = 15;
  notificationData: Notification;
  constructor(
    private utility: UtilityService,
    private notificationService: NotificationService,
    @Optional() @Inject('systemNotification') private systemNotification,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetchNotifications();
    // this.serverEventsService
    //   .addListener(SufinConstants.SYSTEM_NOTIFICATIONEVENT, data => {
    //     this.fetchNotifications();
    //   });
  }

  fetchNotifications() {
    this.notificationService
      .getNewNotifications(this.pageNo, this.pageSize)
      .subscribe(data => {
        data['content'] = data['content'].map(element => ({
          ...element,
          template: this.getNotifiationHTML(element.action, element.params)
        }));
        data['content'] = data['content'].map(element => ({
          ...element,
          navigation: this.getNavigation(element.action, element.params)
        }));
        // this.notificationData = data;
      });
  }
  getNotifiationHTML(action, actionParams) {
    let template;
    try {
      template = this.systemNotification[action].template;
      return this.utility.replaceStringwithValue(template, actionParams);
    } catch (error) {
      console.log('error', error, " for action >", action);
      template = this.systemNotification['not.found'].template
      return this.utility.replaceStringwithValue(template, actionParams);
    }
  }

  getNavigation(action, actionParams) {
    let template;
    try {
      template = this.systemNotification[action].navigation;
      return this.utility.replaceStringwithValue(template, actionParams);
    } catch (error) {
      template = this.systemNotification['not.found'].navigation;
      return this.utility.replaceStringwithValue(template, actionParams);
    }
  }

  onNotificationClick(id, route) {
    this.notificationService.markNotificationAsRead(id).subscribe(data => {
      this.fetchNotifications();
    });
    this.router.navigateByUrl(route);
  }

  viewAllNotifications() {
    this.router.navigate(['/all-notifications']);
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import { SkillHierarchyService } from '../../services/skill-hierarchy.service';
import { SkillConstants } from '../../skill/constants/skill.constant';
import { SkillService } from '../../skill/services/skill.service';


@Component({
  selector: 'hrs-ui-dashboard1',
  templateUrl: './dashboard1.component.html',
  styleUrls: ['./dashboard1.component.scss']
})
export class Dashboard1Component implements OnInit {
  skillData = [];
  toolsData = [];
  filters:any = {};
  filterValues: any = {};

  constructor(private dashboardService: DashboardService, 
    private skillHierarchyService: SkillHierarchyService,
    private skillService: SkillService,
    private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadFilters();
    this.dashboardService.getSkillData(this.filterValues).subscribe(res => {
      this.skillData = this.dashboardService.roundOffValue(res);
    })
    this.dashboardService.getSubSkillData(SkillConstants.TOOLSANDSOFTWARE_COMPETENCY).subscribe(res => {
      this.toolsData = this.dashboardService.roundOffValue(res);
    })
  }
  loadFilters() {
    this.skillHierarchyService.getSkillHierarchy(SkillConstants.FUNCTIONAL).subscribe(data =>
      this.filters.functions = data);
    this.skillHierarchyService.getSkillHierarchy(SkillConstants.GEOGRAPHICAL).subscribe(data =>
      this.filters.geos = data);
      this.skillService.getSkillDomains().subscribe(data=>this.filters.domains = data);
  }

  onSkillSelect(event) {

  }

  onToolSelect(event) {
    
  }
}



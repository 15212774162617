import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterHistoryService {
  private history = [];

  constructor(private router: Router) { }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
        // if (this.history[this.history?.length - 2]) {
        //   localStorage.setItem("routeHistory", JSON.stringify(this.history));
        // }
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(param?) {
    var backStep: number;
    backStep = param ? param : -1;
    window.history.go(backStep);
  }


  public getPreviousRoute(): string {
    return this.history[this.history.length - 2];
  }
}

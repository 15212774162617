import { UploadComponentConfig, UploadEmitEvent } from './../../../../../../../libs/ui-base/src/lib/typedef/api-model-class';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';

@Component({
  selector: 'hrs-ui-upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss']
})
export class UploadDocComponent implements OnInit {
  fileEvent: any = undefined;
  public uploadConfig: UploadComponentConfig;
  fileToBeUploaded: File;
  fileDesc: "";
  constructor(@Optional() public dialogRef: MatDialogRef<UploadDocComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public popupData: any) { }

  ngOnInit(): void {
    this.uploadConfig = {
      inputFieldId: 'skillDoc', // Unique id for HTML’s file input field
      accept: '.txt,  .pdf', // String of extensions allowed while upload
      permissibleSize: 5242880, // maximum allowed size in bytes 5242880bytes = 5MB
      permissibleExtensions: ['txt', 'pdf'] // list of allowed extensions
    };
  }

  save() {
    // let uploadDocObj = { fileToSkillMap: {}, fileDesc: this.fileDesc, file: this.fileToBeUploaded }
    // uploadDocObj.fileToSkillMap[this.fileToBeUploaded.name] = this.popupData.skillCd
    // this.dialogRef.close(uploadDocObj);
    let uploadDocObj = { fileToSkillMap: [], fileDesc: this.fileDesc, file: this.fileToBeUploaded };
    let selectedFilesObj = {
      name: this.fileToBeUploaded.name,
      skillCd: this.popupData.skillCd,
      description: this.fileDesc
    }
    uploadDocObj.fileToSkillMap.push(selectedFilesObj)
    this.dialogRef.close(uploadDocObj);
  }

  close() {
    this.dialogRef.close(null);
  }

  listener(event: UploadEmitEvent): void {
    if (event.continueUpload) {
      this.fileToBeUploaded =
        event.fileEvent.target.files[0];
      console.log(" this.fileToBeUploaded", this.fileToBeUploaded)

    }
  }


}

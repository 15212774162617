import { MatDialog } from '@angular/material/dialog';
import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { GlobalConstants } from '../../constants/global-constants';
import { AuthService } from './../../../../../../libs/ui-base/src/lib/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'hrs-ui-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('login') public loginForm: NgForm;
  @ViewChild('pwdReset') public pwdReset: NgForm;
  emailId;
  password;
  newPassword;
  resetPsNumber;
  resetPasswordKey;
  confNewPassword;
  retrieve: boolean = false;
  loginError: boolean = false;
  invalidPs: boolean = false;
  psRequired: boolean = false;
  resetPasswordMatch: boolean = false;
  validationConstants = GlobalConstants;
  pSNumber;
  constructor(private router: Router, private authService: AuthService, private dialog: MatDialog, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.resetPsNumber = this.activatedRoute.snapshot.queryParams.user;
    this.resetPasswordKey = this.activatedRoute.snapshot.queryParams.key;
    if (this.resetPsNumber && this.resetPasswordKey) {
      this.retrieve = true;
    }
  }

  retrivePwd() {
    if (this.pSNumber) {
      this.authService.intiateResetPassword(this.pSNumber).subscribe(res => {
        const data = this.dialog.open(PopupModalComponent, {
          width: '350px',
          data: {
            message: [`Link to reset password sent to your registerd emailId. `],
            button: ['OK']
          }
        });
      }, error => {
        if (error.status = '422') {
          this.invalidPs = true;
        }
      })
    } else {
      // mark single field of form touched
      this.loginForm.controls['email'].markAsTouched();
    }
  }

  signIn() {
    if (this.loginForm.valid) {
      this.authService.login(this.pSNumber, this.password);

      this.authService.getLoggedInUser().subscribe(user => {
        if (user && user.userId) {
          this.router.navigate(['landing'])
        }
        error => this.showLoginError()
      })
    }
    else {
      this.loginForm.control.markAllAsTouched();
    }
  }
  showLoginError(): void {
    this.loginError = true;
  }

  back() {
    this.retrieve = false;
  }

  submit() {
    if (this.pwdReset.valid) {

      if (this.newPassword != this.confNewPassword) {
        this.resetPasswordMatch = true;
      } else {

        let body = { loginId: this.resetPsNumber, resetKey: this.resetPasswordKey, newPassword: this.confNewPassword }

        this.authService.resetPassword(body).subscribe(res => {
          const data = this.dialog.open(PopupModalComponent, {
            width: '350px',
            data: {
              message: ['Password Changed sucessfully. Please Login to continue '],
              button: ['OK']
            }
          });

          data.afterClosed().subscribe(res => {
            this.retrieve = false;
          });
        })
      }
    } else {
      this.pwdReset.control.markAllAsTouched();
    }
  }

  clearFlags() {
    this.invalidPs = false;
    this.resetPasswordMatch = false;
  }

  viewPass(id) {
    var x = document.getElementById(id);
    if (x['type'] === "password") {
      x['type'] = "text";
    } else {
      x['type'] = "password";
    }

    setTimeout(() => {
      x['type'] = "password";
    }, 1300);
  }

}

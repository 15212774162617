import { SkillConstants } from './../../constants/skill.constant';
import { ActivatedRoute } from '@angular/router';
import { SkillService } from './../../services/skill.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hrs-ui-skill-details',
  templateUrl: './skill-details.component.html',
  styleUrls: ['./skill-details.component.scss'],
})
export class SkillDetailsComponent implements OnInit {
  skillDetails;
  skillRatings;
  constructor(
    @Optional() public dialogRef: MatDialogRef<SkillDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public popupData: any,
    private skillService: SkillService,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    //  this.activatedRoute.queryParams.subscribe((params) => {
    this.skillService
      .getSkillDetails(this.popupData.skillCd)
      .subscribe(async (skillDetails) => {
        this.skillDetails = skillDetails;
        if (
          this.skillDetails.competency ===
          SkillConstants.TOOLSANDSOFTWARE_COMPETENCY
        ) {
          this.skillRatings = await this.skillService
            .getToolsAndSoftwareRatings()
            .toPromise();
        } else if (
          this.skillDetails.competency === SkillConstants.SKILL_COMPETENCY
        ) {
          this.skillRatings = await this.skillService
            .getSkillRatings()
            .toPromise();
        }
      });
    //   });
  }
  closeModal() {
    this.dialogRef.close();
  }
}

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SkillHierarchy } from './../../typedef/api-model';
import { SkillHierarchyService } from './../../services/skill-hierarchy.service';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { TreeviewModule, TreeviewConfig, TreeviewItem, TreeItem } from 'ngx-treeview';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';

@Component({
  selector: 'hrs-ui-skill-tree',
  templateUrl: './skill-tree.component.html',
  styleUrls: ['./skill-tree.component.scss']
})
export class SkillTreeComponent implements OnInit {
  items: TreeviewItem[];
  data: SkillHierarchy[];
  config: TreeviewConfig;
  newSkills: string[];
  selectedSkills: string[] = [];

  constructor(private skillHierarchyService: SkillHierarchyService, private authService: AuthService,
    @Optional() public dialogRef: MatDialogRef<SkillTreeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public popupData: any) { }

  ngOnInit(): void {
    this.selectedSkills = this.popupData.selectedSkills;
    this.config = TreeviewConfig.create(
      {
        hasAllCheckBox: true,
        hasFilter: true,
        hasCollapseExpand: true,
        decoupleChildFromParent: false,
        maxHeight: 400
      })

    this.populateTree();
  }

  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }

  populateTree() {
    this.authService.getLoggedInUser().subscribe(loggedInUser => {
      this.skillHierarchyService.getSkillHierarchy(this.popupData.accordian).subscribe(skillDataList => {
        console.log("service  o/p", skillDataList);
        this.data = skillDataList.skillList;

        const root = [];
        this.data.forEach(node => {
          node.text = node.skillName;
          node.value = node.skillCd;
          delete node.skillName;
          delete node.skillCd;
          delete node['orgId'];
          delete node['skillDesc'];
          delete node['version'];
          node['collapsed'] = false;
          node['checked'] = false;

          //Disable already added skills
          for (let i = 0; i < this.selectedSkills.length; i++) {
            if (this.selectedSkills[i] === node.value) {
              node["checked"] = true;
              node["disabled"] = true;
            }
          }
          // No parentId means top level
          if (!node.parentId) {
            delete node.parentId;
            return root.push(node);
          }


          // Insert node as child of parent in flat array
          const parentIndex = this.data.findIndex(el => el.value === node.parentId);
          if (!this.data[parentIndex]?.children) {
            delete node.parentId;
            return this.data[parentIndex].children = [node];
          }

          this.data[parentIndex]?.children.push(node);
        });

        //console.log (this.data);
        console.log("root", root);
        this.items = this.getItems(root);

      })
    })
  }

  onFilterChange(value: string): void {
    console.log('filter:', value);
  }

  save() {
    this.newSkills = this.newSkills.filter((el) => !this.selectedSkills.includes(el));
    console.log("newSkills", this.newSkills)
    this.dialogRef.close(this.newSkills);
  }

  close() { this.dialogRef.close(); }

}

import { UiEnvSetupService } from './ui-env-setup.service';
import { Inject, Injectable } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ApiClient } from './api-client';
import { UtilityService } from './utility.service';
import { ErrorHttpService } from './error-http.service';


const parsedUrl = new URL(window.location.href);
const baseUrl = parsedUrl.origin;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedInUser = new ReplaySubject<any>(1);
  private serverDown = new ReplaySubject<any>(1);
  properties: any;
  currentRoute: any;
  appInitialized = new BehaviorSubject<boolean>(false);
  
  jwtToken: any;
  private tokenName = "easyskill.access_token";
  
  constructor(
    @Inject('Environment') parentEnvironment: any,
    private router: Router,
    private apiClient: ApiClient,
    private uiEnvService: UiEnvSetupService,
    private errorHttpService: ErrorHttpService,
    private utilityService: UtilityService) {
    this.jwtToken = localStorage.getItem(this.tokenName);
    this.properties = parentEnvironment['oidc'];
  }

  getJwtToken() {
    return this.jwtToken;
  }
  
  clearJwtToken() {
    this.jwtToken = null;
    localStorage.removeItem(this.tokenName);
  }

  login(username: string, password) {
    this.loginToApi(username, password).subscribe(res => {
      this.whoAmI().subscribe(data => {
        this.setLoggedInUser(data);
      })
    })
  }

  logout() {
    this.logoutFromApi().subscribe(res => {
      alert("Logged out Successfully");
      this.router.navigate(['/']);
    })
    this.setLoggedInUser(null);

  }

  resetPassword(body) {
    return this.apiClient.post('/api/userorg/password/reset', body);
  }

  intiateResetPassword(psNumber) {
    return this.apiClient.get('/api/userorg/password/initreset/' + psNumber, { customExemption: ['422'] });
  }

  loginToApi(username: string, password): Observable<any> {
    const form = new FormData();
    form.append('username', username);
    form.append('password', password);
    return this.apiClient.post("/api/login", form);
  }

  logoutFromApi(): Observable<any> {
    return this.apiClient.get('/api/logout');
  }

  whoAmI(): Observable<any> {
    return this.apiClient.get("/api/userorg/whoami", { customExemption: ['401', '404'] });
  }

  setAuth(auth) {
    this.jwtToken = auth.idToken;
    localStorage.setItem(this.tokenName, this.jwtToken);
    this.tryLogin(true);
  }

  private tryLogin(showMessage) {
    this.whoAmI().subscribe(res => {
      this.setLoggedInUser(res);
    },
      (error) => {
        this.jwtToken = null;
        localStorage.removeItem(this.tokenName);
        this.setLoggedInUser(null);
        if (showMessage) {
          this.errorHttpService.handleErrorDiv(error, null, "You are not registered to use Easyskill. Please contact HR.");
        }
      }
    );
  }

  setLoggedInUser(user) {
    this.loggedInUser.lift
    return this.loggedInUser.next(user);
  }

  getLoggedInUser() {
    return this.loggedInUser;
  }

  setServerDown(isServerDown) {
    return this.serverDown.next(isServerDown);    
  }

  isServerDown() {
    return this.serverDown;
  }


  // forgotPassword() {
  //   const forgotPassUrl = `https://${this.properties.tenant}.b2clogin.com/${this.properties.tenant}.onmicrosoft.com/oauth2/v2.0/authorize?p=${this.properties.passwordResetPolicy}&`
  //     + `client_id=${this.properties.clientId}`
  //     + `&nonce=defaultNonce`
  //     + `&response_type=id_token`
  //     + `&redirect_uri=${this.properties.redirectUrl}`
  //     + `&response_mode=query`
  //     + `&scope=openid`
  //     + `&prompt=login`
  //   window.location.href = forgotPassUrl;
  // }

  init() {
    if (this.jwtToken) {
      this.tryLogin(false);
    }

    // this.whoAmI().subscribe(res => {
    //   this.setLoggedInUser(res);
    // },
    //   (error) => {
    //     this.setLoggedInUser(null);
    //   }
    // );
  }
}

export class HomeLandingConstants {
  // Endpoint Url
  public static GET_MYPROFILE_URL = '/api/userorg/myprofile';
  public static LANDING_TILES_URL = '/api/userorg/parenttasks';
  public static GET_SKILL_CHART_DATA_URL = '/api/report/data/avg_profeciency_by_skill';
  public static GET_SUB_SKILL_CHART_DATA_URL = '/api/report/data/sub_skill_data';
  public static GET_SUB_SKILL_TOOLS_DATA = '/api/report/data/tool_proficiency_by_subskill';
  public static GET_EMP_COUNT_AVG_PROF_DATA_URL = '/api/report/data/emp_count_vs_avg_profeciency_by_skill';
  public static GET_SUB_SKILL_PROF_DATA_URL = '/api/report/data/donut_subskills_profeciency';
  public static GET_TOOLS_SOFT_PROF_DATA_URL = '/api/report/data/donut_tools_software_profeciency';
  public static GET_FUNCTION_EMP_COUNT_DATA_URL = '/api/report/data/combo_chart_function_emp_count';
  public static GET_GEOGRAPHY_EMP_COUNT_DATA_URL = '/api/report/data/combo_chart_geography_emp_count';
  public static GET_FUNCTION_RANGE_EMP_COUNT_URL = '/api/report/data/range_chart_emp_count';
  public static GET_GEOGRAPHY_RANGE_EMP_COUNT_URL = '/api/report/data/range_chart_emp_count';
  public static GET_MANAGE_WINDOW_GRID = '/api/hrskills/skills/manage/window/list';
  public static GET_MANAGE_WINDOW_TYPES = '/api/hrskills/skills/manage/window/types';
  public static ADD_WINDOW = '/api/hrskills/skills/manage/window/add';
  public static USER_SEARCH = '/api/hrskills/skills/manage/window/usersearch';
  public static DELETE_WINDOW = '/api/hrskills/skills/manage/window/remove';

  public static IS_EDIT_WINDOW_AVAILABLE  = '/api/hrskills/skills/manage/window/checkDate';






  //
  public static DATE_FORMAT = 'dd-MMM-yyyy';



  //Validation Constants
  public static INCORRECT_PSID_MSG = 'The above PS IDs seem to be incorrect. Please correct, validate, and add.';
  public static STARTDATE_GREATER_THAN_ENDDATE_MSG = "Start Date should be less than End Date";


}
import { ManageWindowActionRenderComponent } from './../components/manage-window/manage-window-action-render.component';
import { HomeLandingConstants } from './home-landing.constant';
import { UtilityService } from 'libs/ui-base/src/lib/services/utility.service';
import { GridModel } from "libs/ui-base/src/lib/typedef/common-models";



const utilityService = new UtilityService(null);

export function getManageWindowDetails(): GridModel {
  return {
    heading: 'Manage Window',
    class: 'agGridClass ag-theme-alpine',
    isPagination: true,
    resetFilter: true,
    exportExcel: true,
    csvFileName: 'Manage_window_',
    serverURL: HomeLandingConstants.GET_MANAGE_WINDOW_GRID,
    columns: [
      // {
      //   headerName: 'Financial Year',
      //   field: 'financialYear',
      //   sortable: true,
      //   filter: true,
      //   width: 200,
      //   resizable: true,
      //   autoHeight: true
      // },
      {
        headerName: 'Type',
        field: 'type',
        sortable: true,
        filter: true,
        width: 150,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Description',
        field: 'description',
        sortable: true,
        flex :2,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        sortable: true,
        flex: 2,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: utilityService.filterDate,
          browserDatePicker: true
        },
        // width: 200,
        resizable: true,
        autoHeight: true
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        sortable: true,
        flex: 2,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: utilityService.filterDate,
          browserDatePicker: true
        },
        // width: 200,
        resizable: true,
        autoHeight: true
      },
      // {
      //   headerName: 'Status',
      //   field: 'status',
      //   sortable: true,
      //   filter: true,
      //   width: 150,
      //   resizable: true,
      //   autoHeight: true
      // },
      {
        headerName: 'Action',
        field: 'action',
        width: 150,
        resizable: false,
        autoHeight: true,
        cellRendererFramework: ManageWindowActionRenderComponent,
        cellRendererParams: {
          nextPageNavigation: {
            parameter: 'status'
          }
        }
      }
    ],
    serverMapping: {
      financialYear: { name: 'financialYear' },
      type: { name: 'type' },
      description: { name: 'description' },
      startDate: { name: 'startDate', mapping: 'dateWithMonthNameTimeFormat' },
      endDate: { name: 'endDate', mapping: 'dateWithMonthNameTimeFormat' },
      status: { name: 'status' },
      id : { name : 'id'}
    }
  };
};
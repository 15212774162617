import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'base-action-render',
  template: ` <span *ngIf="isView()">
      <a
        style="color: blue; cursor: pointer"
        (click)="view(params)"
        title="View"
        ><i class="fa fa-eye actionicon-color" aria-hidden="true"></i
      ></a>
    </span>
    <span *ngIf="editMode"> | </span>
    <span *ngIf="editMode">
      <a
        style="color: blue; cursor: pointer"
        (click)="edit(params)"
        title="Edit"
        ><i class="fa fa-pencil actionicon-color" aria-hidden="true"></i
      ></a>
    </span>
    <span *ngIf="reviewMode && !isView()">
      <a
        style="color: blue; cursor: pointer"
        (click)="review(params)"
        title="Review"
        >Review</a
      >
    </span>`,
  styles: [],
})
export class ActionRenderComponent implements OnInit, AgRendererComponent {
  params: any;
  public actionType: string;
  public editMode: boolean;
  public viewMode: boolean;
  public reviewMode: boolean;
  public actionConfig: any;
  public isHr: boolean = false;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  agInit(params): void {
    this.params = params;
    this.actionConfig = params.actionConfig;
    //logic to decide editMode / viewMode
    if (this.actionConfig) {
      this.editMode = this.actionConfig['isEdit'];
      this.viewMode = this.actionConfig['isView'];
      this.reviewMode = this.actionConfig['isReview'];
      if (this.actionConfig['isHr']) {
        this.isHr = true;
      }
    }
  }

  refresh(): boolean {
    return true;
  }

  view(params) {
    this.navigate(params, 'view');
  }

  edit(params) {
    this.navigate(params, 'edit', this.isHr);
  }

  review(params) {
    this.navigate(params, 'review');
  }

  navigate(params, actionType?: string, isHr?) {
    let data = params.data;
    let navigationDetails = params.nextPageNavigation;
    let navigation = {
      url: null,
      parameter: null,
      readOnly: false,
    };

    navigation.url = navigationDetails.url;
    navigation.parameter = navigationDetails.parameter;
    // navigation.readOnly = navigationDetails.readOnly;

    let queryParamsObj =
      (navigation.parameter &&
        this.getParameterData(navigation.parameter, data)) ||
      {};

    if (actionType) {
      queryParamsObj['actionType'] = actionType;
    }
    if (isHr) {
      queryParamsObj['isHr'] = isHr;
    }

    //When you want to redirect on diffrent Url for View
    if ('viewUrl' in navigationDetails && actionType == 'view') {
      this.router.navigate([navigationDetails.viewUrl], {
        queryParams: queryParamsObj,
      });
    } else {
      this.router.navigate([navigation.url], { queryParams: queryParamsObj });
    }
  }

  getParameterData(field = '', data) {
    let fields = field.split(';');
    let queryParamsObj = {};
    fields.forEach((element) => {
      queryParamsObj[element] = this.resolveObject(element, data);
    });
    return queryParamsObj;
  }

  resolveObject(path, obj = self, separator = '.') {
    let properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  }
  isView() {
    if (this.params.data.skillsReviewed == 'Yes') {
      return true;
    }
  }
}

import { Router } from '@angular/router';
import { LandingService } from './../../services/landing.service';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hrs-ui-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  tilesData: []
  userRoles
  constructor(private authService: AuthService, private landingService: LandingService, private router: Router) { }

  ngOnInit(): void {
    this.authService.getLoggedInUser().subscribe(res => {
      if (res && res.userId) {
        this.userRoles = res.roles;
        this.landingService.getLandingTiles().subscribe(tilesData => {
          console.log("tilesData", tilesData);
          this.tilesData = tilesData;
        })
      }
    })

  }

  navigateToPage(route) {
    //taskAction.forEach(element => {
    // if (element.role === )
    // });
    this.router.navigate([route]);
  }

}

import { ConfirmationPopUpComponent } from 'libs/ui-base/src/lib/components/confirmation-pop-up/confirmation-pop-up.component';
import { SkillConstants } from './../../../constants/skill.constant';
import { UploadDocComponent } from './../../upload-doc/upload-doc.component';
import { PopupModalComponent } from 'libs/ui-base/src/lib/components/popup-modal/popup-modal.component';
import { ViewUploadedDocsComponent } from '../../view-uploaded-docs/view-uploaded-docs.component';
import { MatDialog } from '@angular/material/dialog';
import { SkillTreeComponent } from './../../../../components/skill-tree/skill-tree.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'libs/ui-base/src/lib/services/auth.service';
import { SkillService } from './../../../services/skill.service';
import { Component, OnInit } from '@angular/core';
import { UserSkills, AddSkillRequest } from './../../../../typedef/api-model';
import _ from 'lodash';
import { AppService } from 'apps/hr-skills/src/app/app.component';

@Component({
  selector: 'hrs-ui-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  languageData: UserSkills = new UserSkills();
  addSkillObj: AddSkillRequest = new AddSkillRequest();
  languageProficiency;
  userId;
  uploadDocObj;
  addDocObj = {};
  uploadedFiles = [];
  isSupervisorAction = false;
  isViewMode = false;
  isSkillSaved: boolean;
  locallyAddedSkills = [];
  preSelectedSkills = [];
  dropdownSettings = {};
  constructor(
    private skillService: SkillService,
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initializeDropDownSetting();
    this.activatedRoute.queryParams.subscribe((queryparams) => {
      if (queryparams?.userId) {
        this.userId = queryparams?.userId;
        this.isViewMode = queryparams?.actionType == 'view' ? true : false;
        this.isSupervisorAction = true;
        this.getSkillListAndRatings();
      } else {
        this.authService.getLoggedInUser().subscribe((res) => {
          if (res && res.userId) {
            this.userId = res.userId;
            this.getSkillListAndRatings();
          }
        });
      }
    });
  }

  async getSkillListAndRatings() {
    this.getLanguageData();
    this.languageProficiency = await this.skillService
      .getLanguageProficiency()
      .toPromise();
  }

  addLanguages() {
    this.preSelectedSkills = this.skillService.getPreSelectedSkills();
    let dialogData = {
      accordian: SkillConstants.LANGUAGES,
      selectedSkills: this.preSelectedSkills,
    };

    this.skillService
      .invokeDialog(SkillTreeComponent, SkillConstants.WIDTH_50EM, dialogData)
      .afterClosed()
      .subscribe((newSkills) => {
        this.skillService.fetchAndAddSkills(newSkills, this.languageData);
      });
  }

  getLanguageData() {
    this.skillService
      .getLanguageSkills(this.userId)
      .subscribe((languageData) => {
        languageData = this.converProficiencyInArray(languageData);
        this.languageData = languageData;
        if (this.languageData?.userSkills) {
          if (
            !this.isSupervisorAction &&
            this.languageData.userSkills.status != '1000'
          ) {
            this.isViewMode = true;
          } else if (
            this.isSupervisorAction &&
            this.languageData.userSkills.status == '9000'
          ) {
            this.isViewMode = true;
          }
        }
        if (!this.appService.getIsEditWindowAvailable()) {
          this.isViewMode = true;
        }
      });
  }

  converProficiencyInArray(languageData) {
    if (languageData?.userSkills) {
      languageData.userSkills.skills = languageData?.userSkills?.skills.map(
        (languageObj) => {
          if (languageObj.profiencyValue) {
            let proficiencyArray = [];
            proficiencyArray = languageObj.profiencyValue.split(',');
            languageObj.profiencyValue = proficiencyArray;
            return languageObj;
          }
        }
      );
      return languageData;
    }
  }

  changeLanguageProficiency(e, actionFrom, index) {
    console.log(e.target.value, '>>', index);
    if (actionFrom === 'user') {
      this.languageData.userSkills.skills[index].ratingVal = e.target.value;
    } else {
      this.languageData.userSkills.skills[index].supervisorRating =
        e.target.value;
    }
  }

  onItemSelect(proficiency, index) {
    const proficiencyValues = [];
    proficiencyValues.push(
      this.languageData.userSkills.skills[index].profiencyValue
    );
    proficiencyValues.push(proficiency);
    this.languageData.userSkills.skills[
      index
    ].profiencyValue = proficiencyValues.toString().replace(/(^,)|(,$)/g, '');
  }

  onItemDeSelect(proficiency, index) {
    const proficiencyValues = this.languageData.userSkills.skills[
      index
    ].profiencyValue.split(',');
    const presentProficiencyIndex = proficiencyValues.indexOf(proficiency);
    if (presentProficiencyIndex > -1) {
      proficiencyValues.splice(presentProficiencyIndex, 1);
      this.languageData.userSkills.skills[
        index
      ].profiencyValue = proficiencyValues.toString().replace(/(^,)|(,$)/g, '');
    }
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  saveLanguages(isSubmitCall?) {
    this.addSkillObj.userId = this.languageData.userSkills.userId;
    this.addSkillObj.skills = this.languageData.userSkills.skills.map((v) => ({
      ...v,
      competency: SkillConstants.LANGUAGES,
      profiencyType: SkillConstants.LANGUAGE_PROFICIENCY,
    }));
    for (let i = 0; i < this.addSkillObj.skills.length; i++) {
      this.addSkillObj.skills[i] = _.pick(
        this.addSkillObj.skills[i],
        this.skillService.addSkillReqObjFields
      );
      this.addSkillObj.skills[i].profiencyValue = this.addSkillObj.skills[
        i
      ].profiencyValue
        .toString()
        .replace(/(^,)|(,$)/g, '');
    }
    console.log(this.addSkillObj.skills);
    this.addSkillCall(this.addSkillObj, isSubmitCall);
  }

  addSkillCall(data, isSubmitCall) {
    if (!isSubmitCall) {
      this.skillService.addSkills(data).subscribe((res) => {
        if (res.id) {
          this.dialog
            .open(PopupModalComponent, {
              width: SkillConstants.WIDTH_500PX,
              position: { top: SkillConstants.WIDTH_20PX },
              data: {
                message: ['', SkillConstants.SAVE_SKILL_MSG],
                button: [SkillConstants.OK],
              },
              disableClose: false,
            })
            .afterClosed()
            .subscribe((response) => {
              this.isSkillSaved = true;
              this.getLanguageData();
            });
        }
      });
    } else {
      this.skillService.addSkills(data).subscribe((res) => {
        if (res.id) {
          this.isSkillSaved = true;
          this.getLanguageData();
        }
      });
    }
  }

  deleteSkill(index) {
    const data = this.dialog.open(PopupModalComponent, {
      width: SkillConstants.WIDTH_350PX,
      data: {
        message: [SkillConstants.DELETE_SKILL_MSG],
        button: [SkillConstants.YES, SkillConstants.CANCEL],
      },
    });

    data.afterClosed().subscribe((res) => {
      if (res == SkillConstants.YES) {
        if (
          !this.locallyAddedSkills.includes(
            this.languageData.userSkills.skills[index]?.skillCd
          )
        ) {
          const payload = {
            skillCd: this.languageData.userSkills.skills[index]?.skillCd,
          };
          this.skillService.deleteSkill(payload).subscribe((res) => {
            if (res.result === 'success') {
            }
          });
        }
        this.languageData.userSkills.skills.splice(index, 1);
      }
    });
  }

  initializeDropDownSetting() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      unSelectAllText: '',
      allowSearchFilter: false,
      maxHeight: 100,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No Data Available',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
  }
}
